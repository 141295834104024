import React, { useEffect } from 'react';

const scriptMap = {
    facebook: `!function (f, b, e, v, n, t, s) {
                  if (f.fbq) return; n = f.fbq = function () { n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments) };
                  if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                  n.queue = []; t = b.createElement(e); t.async = !0;
                  t.src = v; s = b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t, s)
                  }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '5758354570847775');
                  fbq('track', 'PageView');`,

    linkedIn: `_linkedin_partner_id = "3812404";
                window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                (function (l) {
                    if (!l) {
                        window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
                        window.lintrk.q = [];
                    }
                    var s = document.getElementsByTagName("script")[0];
                    var b = document.createElement("script");
                    b.type = "text/javascript"; b.async = true;
                    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                    s.parentNode.insertBefore(b, s);
                })(window.lintrk);`,

    clickCease: `var script = document.createElement('script');
                  script.async = true; script.type = 'text/javascript';
                  script.src = 'https://www.clickcease.com/monitor/stat.js';
                  document.head.appendChild(script);`,

    leadfeeder: `(function (ss) {
                    window.ldfdr = window.ldfdr || function () { (ldfdr._q = ldfdr._q || []).push([].slice.call(arguments)); };
                    (function (d, s) {
                        fs = d.getElementsByTagName(s)[0];
                        function ce(src) { var cs = d.createElement(s); cs.src = src; cs.async = 1; fs.parentNode.insertBefore(cs, fs); };
                        ce('https://sc.lfeeder.com/lftracker_v1_' + ss + '.js');
                    })(document, 'script');
                })('JMvZ8g0Ykp972pOd');`,

    googleAds: `const script = document.createElement('script');
                 script.async = true;
                 script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-10825177761';
                 document.head.appendChild(script);
                 script.onload = function () {
                 window.dataLayer = window.dataLayer || [];
                 function gtag() { dataLayer.push(arguments); }
                 gtag('js', new Date());
                 gtag('config', 'AW-10825177761');
                 };`,

    googleAnalytics: `const script = document.createElement('script');
                       script.async = true;
                       script.src = 'https://www.googletagmanager.com/gtag/js?id=G-JT97LSC9FB';
                       document.head.appendChild(script);
                       script.onload = function () {
                       window.dataLayer = window.dataLayer || [];
                       function gtag() { dataLayer.push(arguments); }
                       gtag('js', new Date());
                       gtag('config', 'G-JT97LSC9FB');
                       };`,

    ad_storage: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          
                 new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          
                 j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          
                 })(window,document,'script','dataLayer','GTM-PGWJDZV');`


};

const Cookies = ({ trigger }) => {
    useEffect(() => {
        const keys = Object.keys(scriptMap);

        keys.forEach(key => {
            if (localStorage.getItem(key)) {
                const scriptContent = scriptMap[key];
                const script = document.createElement('script');
                script.innerHTML = scriptContent;
                script.async = true;
                document.body.appendChild(script);

                return () => {
                    document.body.removeChild(script);
                };
            }
        });
    }, [trigger]);

    return null;
};

export default Cookies;
