import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css'
import { API_ENDPOINT } from "../constants";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import ContactForm from '../components/forms/contactForm2';
import NewHomePageForm from '../components/forms/newHomeContactForm'
import Img from '../components/Img';
import Denmark from '../assets/images/Curoflow telemedicine platform in Denmark.webp'
import Switzerland from '../assets/images/Curoflow telemedicine platform in Switzerland.webp'
import Spain from '../assets/images/Curoflow telemedicine platform in Spain.webp'
import Sweden from '../assets/images/Curoflow telemedicine platform in Sweden.webp'
import UKingdom from '../assets/images/Curoflow telemedicine platform in United Kingdom.webp'
import Greece from '../assets/images/Curoflow telemedicine platform in Greece.webp'
import ArabEmirates from '../assets/images/Curoflow telemedicine plattform in United Arab Emirates.webp'
import Marquee from "react-fast-marquee";


import Logo1 from '../assets/images/Acceptus use telemedicine software for digital healthcare.webp'
import Logo2 from '../assets/images/Accesscare use telemedicine software for digital healthcare.webp'
import Logo3 from '../assets/images/Aleris uses telemedicine software for digital patient care.webp'
// import Logo4 from '../assets/images/Bauder uses telemedicine software for digital healthcare.webp'
import Logo5 from '../assets/images/Diagnostiskt centrum hud uses telehealth software.webp'
// import Logo6 from '../assets/images/Dyslexia test centre use telemedicine software for digital healthcare.webp'
import Logo6 from '../assets/images/Dr Vikt uses Curoflow telemedicine software for digital healthcare.webp'

import Logo7 from '../assets/images/Ekenhälsan uses telemedicine software for digital healthcare.webp'
import Logo8 from '../assets/images/Endbright use telemedicine software for digital healthcare.webp'
import Logo9 from '../assets/images/Endogyn uses telemedicine software at OB GYN clinic.webp'
import Logo10 from '../assets/images/Erektify uses telemedicine software for digital healthcare.webp'



import Logo11 from '../assets/images/EUmedical uses telemedicine software for digital patient management.webp'
import Logo12 from '../assets/images/ExpertQ uses telemedicine software for digital healthcare.webp'
import Logo13 from '../assets/images/Femcare uses telemedicine software for digital healthcare.webp'
import Logo14 from '../assets/images/Future clinic trials provide digital healthcare through telemedicine software.webp'
import Logo15 from '../assets/images/Gårdsten vårdcentral use telemedicine software for digital healthcare.webp'
import Logo16 from '../assets/images/Gävle läkarmottagning uses telemedicine software for digital specialist care.webp'
import Logo17 from '../assets/images/Gynhälsan uses telemedicine software for digital fertility care.webp'
// import Logo18 from '../assets/images/Happy healing hut uses telemedicine software for digital healthcare.webp'
import Logo19 from '../assets/images/Holistal uses telemedicine software for digital healthcare.webp'
import Logo20 from '../assets/images/Humana uses Curoflow telemedicine software for digital patient care.webp'
import Logo39 from '../assets/images/Kognitiva teamet uses Curoflow telemedicine platform.webp'





import Logo21 from '../assets/images/Myya use telemedicine software for digital healthcare.webp'
import Logo22 from '../assets/images/Liwia uses telemedicine software for digital primary care.webp'
import Logo23 from '../assets/images/Medect use telemedicine software for digital healthcare.webp'
import Logo24 from '../assets/images/Migränhjälpen uses telemedicine software for digital specialist care.webp'
import Logo25 from '../assets/images/Novius ögonklinik uses telemedicine software for digital specialist care.webp'
import Logo26 from '../assets/images/Optifit uses telemedicine software for weight loss treatments.webp'
import Logo27 from '../assets/images/Sapphire clinics uses telemedicine software for digital patient communication.webp'
import Logo28 from '../assets/images/Speciallaege use telemedicine software for digital healthcare.webp'
import Logo29 from '../assets/images/Sportive use telemedicine software for digital healthcare.webp'
// import Logo30 from '../assets/images/Velora use telemedicine software for digital healthcare.webp'
import Logo31 from '../assets/images/Wellgo uses telemedicine software for weight loss treatments.webp'
import Logo32 from '../assets/images/Älvsjö sexuell hälsa has telemedicine software for digital healthcare.webp'
import Logo33 from '../assets/images/MTD Online uses Curuflow telemedicine platform.webp'
import Logo34 from '../assets/images/Vitaminkliniken uses Curuflow telemedicine platform.webp'

import Logo35 from '../assets/images/Al-Thiqa Pharmacy uses Curoflow Telemedicine platform.webp'
import Logo36 from '../assets/images/Direkt Hälsa uses Curoflow Telemedicine platform.webp'
import Logo37 from '../assets/images/Vital Vårdcentral Malmö uses Curoflow Telemedicine platform.webp'
import Logo38 from '../assets/images/e-doctor uses curoflow telemedicine platform.webp'




import { Waypoint } from 'react-waypoint';

import Slider1 from '../assets/images/Nurse_working_with_Curoflow_telemedicine_platform_in_a_primary_care_clinic.webp'
import Slider1Mob from '../assets/images/Digital_healthcare_through_Curoflow_white_label_telemedicine_platform.webp'

import Slider2 from '../assets/images/Digital_healthcare_through_Curoflow_telemedicine_platform.webp'
import Slider2Mob from '../assets/images/Digital_healthcare_through_the_Curoflow_telemedicine_platform.webp'

import Slider3 from '../assets/images/Providing_digital_healthcare_through_the_GDPR-secure_telemedicine_platform.webp'
import Slider3Mob from '../assets/images/Providing_digital_healthcare_through_a_GDPR-secure_telemedicine_software.webp'

import Slider4 from '../assets/images/Nurse_using_Curoflow_telemedicine_platform_at_primary_care_clinic.webp'
import Slider4Mob from '../assets/images/Nurse_using_the_Curoflow_telemedicine_software_at_primary_care_clinic.webp'

import Slider5 from '../assets/images/Nurse_treating_patients_online_through_Curoflow_telemedicine_platform.webp'
import Slider5Mob from '../assets/images/Nurse_treating_patients_online_through_the_Curoflow_telemedicine_platform.webp'

import ImageSection2 from '../assets/images/Lunch_med_Curoflow_for_digitala_vardtjanster.webp'

import SupportForm from '../components/forms/supportForm4';
import SupportForm1 from '../components/forms/supportForm1';

import Slider from "react-slick";

import {
    Container,
    Row,
    Col,
    Carousel,
    Collapse,
    CarouselItem
} from 'reactstrap';

import arrowDown from '../assets/svg/arrow-down.svg';
import chat from '../assets/images/chat.png';
import ChatForm from '../components/forms/chatForm3';
import { homePage } from './homePageData';


var striptags = require('striptags');

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

let homePageTest = {
    firstSliderItems:
        [
            {
                "title": {
                    "se": "Nå den fulla potentialen av digifysisk vård med en plattform som ger er flow",
                    "en": "Provide digital healthcare with a telemedicine platform that gives you flow"
                },
                "description": {
                    "se": "<h5>M&ouml;t framtidens sjukv&aring;rd med Curoflow - en helt&auml;ckande digital v&aring;rdplattform f&ouml;r alla typer av v&aring;rdgivare</h5>",
                    "en": "<h5>Say hello to the future of healthcare with Curoflow - the comprehensive digital healthcare and telemedicine platform</h5>"
                },
                "link": "",
                "imageOrVideo": Slider1,
                "alt": "Nurse working with Curoflow telemedicine platform at primary care clinic",
                "edit": 0,
                "imageOrVideoMobile": Slider1Mob
            },
            {
                "title": {
                    "se": "Öka bekvämligheten genom att låta patienter nå er mottagning online",
                    "en": "Increase convenience by enabling patients to reach your clinic online"
                },
                "description": {
                    "se": "<h5>Tillg&auml;nglig v&aring;rd f&ouml;r era patienter med chatt, video, medicinska formul&auml;r, bokningsbara kalendrar, recept, resultat och s&aring; mycket mer. Ni best&auml;mmer!</h5>",
                    "en": "<h5>Easy access for your patients with chat, video, medical forms, bookable calendars, prescriptions, results and so much more. You decide!</h5>"
                },
                "imageOrVideo": Slider2,
                "alt": "Digital healthcare through Curoflow telemedicine platform",
                "link": "var-plattform/digital-patientportal-i-vardplattformen",
                "edit": 1,
                "imageOrVideoMobile": Slider2Mob
            },
            {
                "title": {
                    "se": "Curoflow digital vårdplattform är valet för GDPR-efterlevnad",
                    "en": "Curoflow telemedicine platform is the choice for GDPR-compliance"
                },
                "description": {
                    "se": "<h5>Plattformen &auml;r CE-m&auml;rkt enligt MDR och vi behandlar all data inom EU f&ouml;r strikt efterlevnad av GDPR</h5>",
                    "en": "<h5>The platform is CE-marked according to the MDR and we process all data within the EU for strict compliance with the GDPR</h5>"
                },
                "link": "gdpr-efterlevnad-i-Curoflow-digital-vardplattform",
                "imageOrVideo": Slider3,
                "alt": "Providing digital healthcare through the GDPR-secure telemedicine platform",
                "edit": 2,
                "imageOrVideoMobile": Slider3Mob
            },
            {
                "title": {
                    "se": "Vill ni ha fler och mer nöjda patienter på er vårdcentral?",
                    "en": "Do you want more and happier patients at your primary care clinic?"
                },
                "description": {
                    "se": "<h5>Curoflow v&aring;rdplattform revolutionerar prim&auml;rv&aring;rden med digitala l&ouml;sningar som hyllas av b&aring;de v&aring;rdpersonal och patienter</h5>",
                    "en": "<h5>The Curoflow telemedicine platform is revolutionizing primary care with digital solutions praised by both healthcare professionals and patients</h5>"
                },
                "imageOrVideo": Slider4,
                "alt": "Nurse using Curoflow telemedicine platform at primary care clinic",
                "link": "vi-hjalper/digital-vardplattform-for-primarvard",
                "edit": 3,
                "imageOrVideoMobile": Slider4Mob
            },
            {
                "title": {
                    "se": "Engagera och monitorera era patienter för mer kontinuerlig vård",
                    "en": "Engage and monitor your patients for more continuous care"
                },
                "description": {
                    "se": "<h5>Genom automatisk insamling av patienternas hälsodata erbjuder Curoflow ett dynamiskt underlag för kliniska beslut med statistik och diagram</h5>",
                    "en": "<h5>With automatic collection of patient health data presented in graphs, Curoflow provides a versatile tool for support in clinical decision-making</h5>"
                },
                "imageOrVideo": Slider5,
                "alt": "Nurse treating patients online through Curoflow telemedicine platform",
                "link": "var-plattform/kronikermonitorering-med-data-genom-vardplattformen",
                "edit": 4,
                "imageOrVideoMobile": Slider5Mob
            }
        ]
}



let homePageTestSection2 = {
    title:
    {
        "se": "Test Swe",
        "en": "For healthcare providers around the globe"
    },

    description:
    {
        "se": "Test description Swe",
        "en": "The Curoflow platform is developed in Sweden and designed to improve healthcare worldwide. Our white-label all-in-one operating software is easily adapted to different languages and country specific standards. Here are examples of countries where Curoflow is being used today and we are constantly expanding our platform to serve customers in new regions."
    }
}


class HomePage extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = { activeIndex: 0 };
        this.state = { activeIndexDesktop: 0 };
        this.nextMobile = this.nextMobile.bind(this);
        this.previousMobile = this.previousMobile.bind(this);
        this.nextDesktop = this.nextDesktop.bind(this);
        this.previousDesktop = this.previousDesktop.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.onExitingDesktop = this.onExitingDesktop.bind(this);
        this.onExitedDEsktop = this.onExitedDEsktop.bind(this);
        this.goToIndexDesktop = this.goToIndexDesktop.bind(this);
        this.player = React.createRef();

        this.ref = React.createRef();
        // let initialData = props.initialData ? props.initialData : {};
        this.state = {
            showHome2: false,
            showSection2: false,
            promotedProjects: [],
            poolActive: false,
            chatForm: false,
            yScroll2: 0,
            activeIndex: 0,
            procenat: 0,
            procenat2: 0,
            procenat3: 0,
            contactPopup: false,
            loading2: false,
            homePage: {},
            mobileSlides: [],
            desktopSlides: [],
            ...props.initialData,
            activeIndexDesktop: 0,
            supportForm: false,
            supportStatus: false,
            homeWeAssist: [],
            activeSliderIndex: 0,
            activeSliderIndex2: 0,
            _first: 0,
            _second: 0,
            _third: 0,
            supportForm1: false,
            windowWidth: false,


        };

    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    onExitingDesktop() {
        this.animatingDesktop = true;
    }

    onExitedDEsktop() {
        this.animating = false;
    }

    nextMobile() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.mobileSlides.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previousMobile() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.mobileSlides.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    nextDesktop() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndexDesktop === this.state.desktopSlides.length - 1 ? 0 : this.state.activeIndexDesktop + 1;
        this.setState({ activeIndexDesktop: nextIndex });
    }

    previousDesktop() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndexDesktop === 0 ? this.state.desktopSlides.length - 1 : this.state.activeIndexDesktop - 1;
        this.setState({ activeIndexDesktop: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    goToIndexDesktop(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }



    listenToScroll = () => {
        const yScroll2 =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll2: yScroll2,
        })
    }

    sendMessage1 = (data) => {
        this.props.setRecaptchaCallback(() => {
            // if (!data?.message?.trim()) {
            //     return this.setState({ contactFormMessageErr: true });
            // } else this.setState({ contactFormMessageErr: false })

            console.log('test',);

            console.log('data', data, 'props', this.props.allowSubmit);
            console.log('isMobile', this.props.isMobile);


            if (data && data.checked && this.props.allowSubmit) {
                if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@business.com') !== -1 || data.email.toLowerCase().indexOf('@admin.com') !== -1 || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
                    return this.setState({ contactFormError: 'Please fill in your work email' })
                } else this.setState({ contactFormError: null })

                // if (data.message?.length < 25) return this.setState({ contactFormMessageError: 'Please use at least 25 letters' });
                //     else this.setState({ contactFormMessageError: null })

                data.lang = this.props.lang
                data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
                data.bookADemo = false
                data.page = this.props.lang === "en" ? "home page" : "startsida"
                data.token = this.props.recaptchaToken
                data.isMobile = this.props.isMobile
                data.osMobileNameUA = this.props.osMobileNameUA
                data.browserName = this.props.browserName
                data.browserNameUA = this.props.browserNameUA
                this.setState({
                    loading2: true
                }, () => {

                    fetch(API_ENDPOINT + '/data/contact/new', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ data })
                    }).then(parseJSON).then(({ result, status }) => {
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.setState({
                                supportForm: false
                            }, () => {
                                const path = 'thank-you';
                                this.props[0].history.push(`/${path}`);
                            })

                        }
                    })

                })
            }
        })

        this.props.updateToken();


    }



    sendMessage4 = (data) => {

        this.props.setRecaptchaCallback(() => {
            console.log(this.props.recaptchaToken, 'token');
            if (data && data.checked) {
                data.lang = this.props.lang
                data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
                data.bookADemo = false
                data.page = this.props.lang === "en" ? "home page" : "startsida"
                data.token = this.props.recaptchaToken
                this.setState({
                    loading2: true
                }, () => {

                    fetch(API_ENDPOINT + '/data/contact/homepage', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ data })
                    }).then(parseJSON).then(({ result, status }) => {
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.setState({
                                supportForm: false
                            }, () => {
                                const path = 'thank-you';
                                this.props[0].history.push(`/${path}`);
                            })

                        }
                    })

                })
            }
        })

        this.props.updateToken();


    }




    sendMessage2 = (data) => {
        this.props.setRecaptchaCallback(() => {

            if (data && data.checked && this.props.allowSubmit) {

                if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@business.com') !== -1 || data.email.toLowerCase().indexOf('@admin.com') !== -1 || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
                    return this.setState({ contactFormError2: 'Please fill in your work email' })
                } else this.setState({ contactFormError2: null })

                // if (data.message?.length < 25) return this.setState({ contactFormMessageError2: 'Please use at least 25 letters' });
                //     else this.setState({ contactFormMessageError2: null })


                data.lang = this.props.lang
                data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
                data.bookADemo = true
                data.page = this.props.lang === "en" ? "home page" : "startsida"
                data.token = this.props.recaptchaToken
                data.isMobile = this.props.isMobile
                data.osMobileNameUA = this.props.osMobileNameUA
                data.browserName = this.props.browserName,
                    data.browserNameUA = this.props.browserNameUA

                this.setState({
                    loading2: true
                }, () => {

                    fetch(API_ENDPOINT + '/data/contact/new', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ data })
                    }).then(parseJSON).then(({ result, status }) => {
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.setState({
                                supportForm: false
                            }, () => {
                                const path = 'thank-you';
                                this.props[0].history.push(`/${path}`);
                            })

                        }
                    })

                })
            }
        })
        this.props.updateToken();

    }





    sendMessage8 = (data) => {
        this.props.setRecaptchaCallback(() => {

            if (data && data.checked && this.props.allowSubmit) {

                // if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1)) {
                //     return this.setState({ contactFormError2: 'Please fill in your work email' })
                // } else this.setState({ contactFormError2: null })
                if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@business.com') !== -1 || data.email.toLowerCase().indexOf('@admin.com') !== -1 || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
                    return this.setState({ contactFormError3: 'Please fill in your work email' })
                } else this.setState({ contactFormError3: null })

                // if (data.message?.length < 25) return this.setState({ contactFormMessageError3: 'Please use at least 25 letters' });
                // else this.setState({ contactFormMessageError3: null })


                data.lang = this.props.lang
                data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
                data.bookADemo = true
                data.page = this.props.lang === "en" ? "home page" : "startsida"
                data.token = this.props.recaptchaToken
                data.isMobile = this.props.isMobile
                data.osMobileNameUA = this.props.osMobileNameUA
                data.browserName = this.props.browserName,
                    data.browserNameUA = this.props.browserNameUA

                this.setState({
                    loading2: true
                }, () => {

                    fetch(API_ENDPOINT + '/data/contact/new', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ data })
                    }).then(parseJSON).then(({ result, status }) => {
                        if (result.error) {
                            this.setState({ error: result.error })
                        } else {
                            this.setState({
                                supportForm: false
                            }, () => {
                                const path = 'thank-you';
                                this.props[0].history.push(`/${path}`);
                            })

                        }
                    })

                })
            }
        })
        this.props.updateToken();

    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                console.log(JSON.stringify(data));
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
    }

    increment = () => {
        let first = 46;


        if (first > this._first) {
            this._first += 1;
            this?.setState?.({ _first: this._first });
        }
        else {
            if (this._first > first) return this.setState({ _first: first })
            return
        }
        // if (second > this.state._second) {
        //     this?.setState?.(prev => ({ ...prev, _second: (prev?._second || 0) + 1 }));


        // }
        // if (third > this.state._third) {
        //     this?.setState?.(prev => ({ ...prev, _third: (prev?._third || 0) + 1 }));




        // }
        setTimeout(() => {
            this.increment()
        }, 10)
    }




    increment1 = () => {
        // let first = 46;
        let second = 300;
        // let third = 271;
        // if (first > this.state._first || second > this.state._second || third > this.state._third) {
        // if (first > this.state._first) {
        //     this?.setState?.(prev => ({ ...prev, _first: (prev?._first || 0) + 1 }));


        // }
        // if (second > this.state._second) {
        //     this?.setState?.(prev => ({ ...prev, _second: prev?._second + 4 }));


        // }
        // // if (third > this.state._third) {
        // //     this?.setState?.(prev => ({ ...prev, _third: (prev?._third || 0) + 1 }));




        // // }
        // else {
        //     if (this.state._second > second) return this.setState({_second: second})
        //     return
        // }

        if (second > this._second) {
            this._second += 4;
            this?.setState?.({ _second: this._second });
        }
        else {
            if (this._second > second) return this.setState({ _second: second })
            return
        }


        setTimeout(() => {
            this.increment1()
        }, 8)


    }

    increment2 = () => {
        // let first = 46;
        // let second = 300;
        let third = 271;
        // if (first > this.state._first || second > this.state._second || third > this.state._third) {
        // if (first > this.state._first) {
        //     this?.setState?.(prev => ({ ...prev, _first: (prev?._first || 0) + 1 }));


        // }
        // if (second > this.state._second) {
        //     this?.setState?.(prev => ({ ...prev, _second: (prev?._second || 0) + 1 }));


        // }
        // if (third > this.state._third) {
        //     this?.setState?.(prev => ({ ...prev, _third: prev?._third + 4 }));
        // }
        // else {
        //     if (this.state._third > third) return this.setState({_third: third})
        //     return
        // }

        if (third > this._third) {
            this._third += 4;
            this?.setState?.({ _third: this._third });
        }
        else {
            if (this._third > third) return this.setState({ _third: third })
            return
        }


        setTimeout(() => {
            this.increment2()
        }, 8)


    }

    componentDidMount() {
        this._first = 0;
        this._second = 0;
        this._third = 0;

        this.setState({ showSection2: true })

        if (this.props.lang === 'se') this.setState({ showHome2: true });
        if (this.props.lang === 'en' || (this.props?.[0]?.match?.path === '/en' && this.props?.[0]?.match?.url === '/en')) this.setState({ showSection2: true });




        // if (this.props?.[0]?.location?.pathname === '/en') {
        //     const el = document.createElement('a');
        //     el.href = '/?lang=en';


        //     document.body.appendChild(el);
        //     el.click();
        //     el.remove();
        //     return;
        // }

        if (this.props?.[0]?.location?.search?.includes?.('lang=en')) {
            this.props.changeLang?.('en');
            this.props[0]?.history?.replace?.('/');
        }

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        // this.get();
        this.updateWindowDimensions();


        setTimeout(() => {
            let origLang = this.props.lang;
            console.log('orig lang: ', origLang);


            this.props.changeLang(this.props.lang == 'en' ? 'se' : 'en')

            setTimeout(() => {
                this.props.changeLang(origLang)

            }, 1)

        }, 1);
        // window.addEventListener('scroll', this.listenToScroll)

        AOS.init({
            // initialise with other settings
            duration: 1000
        });

        fetch(API_ENDPOINT + "/data/pages/all-weassist", {
            method: "GET",
            headers: {
                "content-type": "application/json",
            },
        })
            .then(parseJSON)
            .then(({ result, status }) => {
                // console.log(result);
                let resultArray = result.map((item) => {
                    return {
                        alias: item.alias,
                        name: item.name
                    }
                })

                this.setState({
                    homeWeAssist: [...resultArray]
                })


                //   if (result.error) {
                //     this.setState({ error: result.error });
                //   } else {
                //     this.setState(
                //       {
                //         dropdownOpen: !this.state.dropdownOpen,
                //         children: [...result],
                //       },
                //       () => {
                //         let children = [...this.state.children];
                //         for (let i = 0; i < this.state.children.length; i++) {
                //           // if (children[i].name["en"] === "Go Flow") {
                //           //   children[i].position = 1;
                //           // }
                //           // if (children[i].name["en"] === "Partners") {
                //           //   children[i].position = 5;
                //           // }

                //           if (
                //             this.state.children[i].section &&
                //             this.state.children[i].section.length
                //           ) {
                //             for (
                //               let m = 0;
                //               m < this.state.children[i].section.length;
                //               m++
                //             ) {
                //               if (
                //                 this.state.children[i].section[m] &&
                //                 this.state.children[i].section[m].item &&
                //                 this.state.children[i].section[m].item[0] &&
                //                 this.state.children[i].section[m].item[0].isTitleLink &&
                //                 this.state.children[i].section[m].item[0].title &&
                //                 this.state.children[i].section[m].item[0].titleLinkText
                //               ) {
                //                 children.splice(i + 1, 0, {
                //                   name: this.state.children[i].section[m].item[0].title,
                //                   title:
                //                     this.state.children[i].section[m].item[0]
                //                       .titleLinkText,
                //                   alias: this.state.children[i].alias,
                //                   sectionID: "section" + m,
                //                   additionalLink: true,
                //                 });
                //               }
                //             }
                //           }<
                //         }
                //         this.setState({
                //           children,
                //         });

                //         if (this.state.apiName === "all-aboutus") {
                //           let children = this.state.children;
                //           for (let i = 0; i < this.state.otherAboutUsLinks.length; i++) {
                //             children.push({
                //               ...this.state.otherAboutUsLinks[i],
                //             });
                //           }
                //           children.sort((a, b) => {
                //             return a.position - b.position;
                //           });
                //           this.setState({
                //             children,
                //           });
                //         }
                //       }
                //     );
                //   }



            });

        if (typeof window !== 'undefined') {
            this.setState({
                windowWidth: window.innerWidth
            })
        }


    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.lang !== this.props.lang) {
            if (this.props.lang === 'se') this.setState({ showHome2: true });
            else this.setState({ showHome2: false })
        }

        if (prevProps?.lang !== this.props.lang) {
            if (this.props.lang === 'en' || (this.props?.[0]?.match?.path === '/en' && this.props?.[0]?.match?.url === '/en')) this.setState({ showSection2: true });
            else this.setState({ showSection2: false })
        }
        // if (prevProps[0].location.pathname != this.props[0].location.pathname) {
        //     this.get()
        // }
        // if (prevState.homePage != homePage) {

        //     if (homePage && homePage[1] && homePage[1].gallery1 && homePage[1].gallery1.length) {
        //         let arr = [];
        //         for (let i = 0; i < homePage[1].gallery1.length; i++) {
        //             arr.push({ src: API_ENDPOINT + homePage[1].gallery1[i] })
        //         }
        //         // return {
        //         this.setState({
        //             mobileSlides: arr
        //         })


        //         // }
        //     }
        //     if (homePage && homePage[1] && homePage[1].gallery2 && homePage[1].gallery2.length) {
        //         let arr = [];
        //         for (let i = 0; i < homePage[1].gallery2.length; i++) {
        //             arr.push({ src: API_ENDPOINT + homePage[1].gallery2[i] })
        //         }
        //         this.setState({
        //             desktopSlides: arr
        //         })
        //         // return {
        //         // desktopSlides: arr
        //         // }
        //     }

        //     if (homePage?.secondSliderITems?.[0]?.tabname?.[this.props.lang] && this.state.width && this.state.width <= 991) {
        //         this.setState({ slidesOnMobile: homePage?.secondSliderITems?.[0]?.tabname?.[this.props.lang] })
        //     }

        //     if (homePage?.lastSliderITems?.[0]?.tabname?.[this.props.lang] && this.state.width && this.state.width <= 991) {
        //         this.setState({ slidesOnMobile1: homePage?.lastSliderITems?.[0]?.tabname?.[this.props.lang] })
        //     }

        // }

        if (prevProps?.lang !== this.props.lang) {
            if (homePage?.secondSliderITems?.[0]?.tabname?.[this.props.lang] && this.state.width && this.state.width <= 991) {
                this.setState({ slidesOnMobile: homePage?.secondSliderITems?.[0]?.tabname?.[this.props.lang] })
            }

            if (homePage?.lastSliderITems?.[0]?.tabname?.[this.props.lang] && this.state.width && this.state.width <= 991) {
                this.setState({ slidesOnMobile1: homePage?.lastSliderITems?.[0]?.tabname?.[this.props.lang] })
            }
        }
    }

    componentWillUnmount() {
        // window?.removeEventListener('scroll', this.listenToScroll);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    sendMessage3 = (data) => {
        if (data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading3: true
            }, () => {

                fetch(window.location.origin == 'https://qa.curoflow.se' ? 'https://testapi.curoflow.se/support-category/tiket-site' : 'https://dashboardapi.curoflow.se/support-category/tiket-site', {
                    // fetch('http://localhost:4005/support-category/tiket-site', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    if (status === 429) {
                        this.setState({
                            supportStatus: true,
                            loading3: false
                        })
                    }
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        this.props[0].history.push("/news")
                    }
                })

            })
        }

    }

    render() {

        //    console.log(homePage)
        return (
            <div>
                {
                    this.state.supportForm ?
                        <div className='overlay overlay-active' onClick={() => {
                            this.setState({
                                supportForm: false
                            })
                        }}></div>
                        :
                        null
                }

                {
                    this.state.supportForm1 ?
                        <div className='overlay overlay-active' onClick={() => {
                            this.setState({
                                supportForm1: false
                            })
                        }}></div>
                        :
                        null
                }





                {/*-------------------------------------------------------book a demo form --------------------------------------------*/}


                <div className={this.state.supportForm ? 'contact-popup open' : 'contact-popup'}>
                    <span className="close-icon" onClick={() => {
                        this.setState({
                            supportForm: false
                        })
                    }}>x</span>
                    {
                        !this.state.loading3 && !this.state.supportStatus ?
                            <SupportForm onSubmit={this.sendMessage8} lang={this.props.lang} contactFormError={this.state.contactFormError3} contactFormMessageError={this.state.contactFormMessageError3} />
                            : null
                    }
                    {
                        this.state.loading3 ?
                            <Player
                                ref={this.player}
                                autoplay={true}
                                onEvent={(event) => {
                                    if (event == 'complete') {
                                        this.setState({
                                            supportForm: false,
                                            loading3: false
                                        })
                                    }
                                }}
                                //keepLastFrame={true}
                                //loop={false}
                                controls={true}
                                src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                style={{ height: '300px', width: '300px' }}
                            ></Player>
                            :
                            null
                    }
                    {this.state.supportStatus && <h2 className='status-popup support-status'>{'Please, try again later!'.translate(this.props.lang)}</h2>}
                </div>

                <div className={this.state.supportForm1 ? 'contact-popup open' : 'contact-popup'}>
                    <span className="close-icon" onClick={() => {
                        this.setState({
                            supportForm1: false
                        })
                    }}>x</span>
                    {
                        !this.state.loading3 && !this.state.supportStatus ?
                            <SupportForm1 onSubmit={this.sendMessage2} lang={this.props.lang} contactFormError={this.state.contactFormError2} contactFormMessageError={this.state.contactFormMessageError2} />
                            : null
                    }
                    {
                        this.state.loading3 ?
                            <Player
                                ref={this.player}
                                autoplay={true}
                                onEvent={(event) => {
                                    if (event == 'complete') {
                                        this.setState({
                                            supportForm1: false,
                                            loading3: false
                                        })
                                    }
                                }}
                                //keepLastFrame={true}
                                //loop={false}
                                controls={true}
                                src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                style={{ height: '300px', width: '300px' }}
                            ></Player>
                            :
                            null
                    }
                    {this.state.supportStatus && <h2 className='status-popup support-status'>{'Please, try again later!'.translate(this.props.lang)}</h2>}
                </div>

                {/*-------------------------------------------------------end book a demo form --------------------------------------------*/}


                {/**First section home page */}

                {/* <section className='home-slider'>
                    {
                        homePage?.firstSliderItems?.length ? <Slider ref={slider => (this.slider = slider)}
                            {...{
                                dots: true,
                                infinite: true,
                                speed: 1500,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                fade: false,
                                swipe: false,
                                responsive: [
                                    {
                                        breakpoint: 576,
                                        settings: {
                                            speed: 100,
                                            dots: true,
                                            swipe: true
                                        },
                                    }
                                ]
                            }}>

                            {
                                homePage?.firstSliderItems?.map?.((item, id) => {
                                    return (
                                        <div key={item?.imageOrVideo || id}>
                                            <Img className='desktop-version' src={typeof window !== "undefined" && window.innerWidth < 600 ? API_ENDPOINT + (item?.imageOrVideoMobile) : API_ENDPOINT + (item?.imageOrVideo)}
                                                alt={(item?.imageOrVideo.replace('/uploads/', '').replace(/_/g, ' ')?.slice(0, -4))}


                                            />
                                            <div className='slider-text'>
                                                <h1>{item?.title?.[this.props.lang]}</h1>
                                                <div className='subtitle-slider' dangerouslySetInnerHTML={{ __html: Object.translate(item, 'description', this.props.lang) }}></div>
                                                {
                                                    id === 0 ?
                                                        <button className='slider-button' onClick={() => {
                                                            this.setState({
                                                                supportForm: true
                                                            })
                                                        }}>{'Book a demo'.translate(this.props.lang)}
                                                        </button>
                                                        :
                                                        <button className='slider-button' onClick={() => {
                                                            this.props[0].history.push(item?.link)
                                                        }}>{"Learn more".translate(this.props.lang)}
                                                        </button>
                                                }


                                            </div>
                                        </div>
                                    )
                                }


                                )
                            }


                        </Slider>
                            :
                            null
                    }
                </section>  */}
                {/**888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888 */}


                <section className='home-slider'>
                    {
                        homePageTest?.firstSliderItems?.length ? <Slider ref={slider => (this.slider = slider)}
                            {...{
                                dots: true,
                                infinite: true,
                                speed: 1500,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                fade: false,
                                swipe: false,
                                responsive: [
                                    {
                                        breakpoint: 576,
                                        settings: {
                                            speed: 100,
                                            dots: true,
                                            swipe: true
                                        },
                                    }
                                ]
                            }}>

                            {
                                homePageTest.firstSliderItems.map((item, id) => {
                                    if (this.state.windowWidth && this.state.windowWidth > 600) {
                                        return (
                                            <div key={item.imageOrVideo + '_' + id}>
                                                <Img className='desktop-version' src={item.imageOrVideo}
                                                    // alt={(item?.imageOrVideo.replace('/uploads/', '').replace(/_/g, ' ')?.slice(0, -14))}
                                                    alt={item?.alt}



                                                />
                                                <div className='slider-text'>
                                                    <h1>{item?.title?.[this.props.lang]}</h1>
                                                    <div className='subtitle-slider' dangerouslySetInnerHTML={{ __html: Object.translate(item, 'description', this.props.lang) }}></div>
                                                    {
                                                        id === 0 ?
                                                            <button className='slider-button' onClick={() => {
                                                                this.setState({
                                                                    supportForm: true
                                                                })
                                                            }}>{'Book a demo'.translate(this.props.lang)}
                                                            </button>
                                                            :
                                                            <button className='slider-button' onClick={() => {
                                                                this.props[0].history.push(item?.link)
                                                            }}>{"Learn more".translate(this.props.lang)}
                                                            </button>
                                                    }


                                                </div>
                                            </div>
                                        )

                                    } else if (this.state.windowWidth && this.state.windowWidth < 600) {
                                        return (
                                            <div key={item.imageOrVideoMobile + '_' + id}>
                                                <Img className='desktop-version' src={(item.imageOrVideoMobile)}
                                                    alt={item?.alt}


                                                />
                                                <div className='slider-text'>
                                                    <h1>{item?.title?.[this.props.lang]}</h1>
                                                    <div className='subtitle-slider' dangerouslySetInnerHTML={{ __html: Object.translate(item, 'description', this.props.lang) }}></div>
                                                    {
                                                        id === 0 ?
                                                            <button className='slider-button' onClick={() => {
                                                                this.setState({
                                                                    supportForm: true
                                                                })
                                                            }}>{'Book a demo'.translate(this.props.lang)}
                                                            </button>
                                                            :
                                                            <button className='slider-button' onClick={() => {
                                                                this.props[0].history.push(item?.link)
                                                            }}>{"Learn more".translate(this.props.lang)}
                                                            </button>
                                                    }


                                                </div>
                                            </div>
                                        )
                                    }
                                    else if (typeof window == 'undefined') {
                                        return (
                                            <div key={item.imageOrVideoMobile + '_' + id}>
                                                <Img className='desktop-version' src={(item.imageOrVideoMobile)}
                                                    alt={item?.alt}


                                                />
                                                <div className='slider-text'>
                                                    <h1>{item?.title?.[this.props.lang]}</h1>
                                                    <div className='subtitle-slider' dangerouslySetInnerHTML={{ __html: Object.translate(item, 'description', this.props.lang) }}></div>
                                                    {
                                                        id === 0 ?
                                                            <button className='slider-button' onClick={() => {
                                                                this.setState({
                                                                    supportForm: true
                                                                })
                                                            }}>{'Book a demo'.translate(this.props.lang)}
                                                            </button>
                                                            :
                                                            <button className='slider-button' onClick={() => {
                                                                this.props[0].history.push(item?.link)
                                                            }}>{"Learn more".translate(this.props.lang)}
                                                            </button>
                                                    }


                                                </div>
                                            </div>
                                        )
                                    }

                                }


                                )
                            }


                        </Slider>
                            :
                            null
                    }
                </section>


                {/**888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888 */}

                {/**end first section home page */}

                {/**new section 2 home page */}


                <section style={this.state.showSection2 ? { display: 'block' } : { display: 'none' }} className={`home2Section`}>
                    <div className='container-curoflow'>
                        <h1>{homePageTestSection2?.title?.en}</h1>
                        <h6>{homePageTestSection2?.description?.en}</h6>
                        <div className='allCountries'>
                            <img className="countries" src={Switzerland} alt={'Curoflow telemedicine platform in Switzerland'} />
                            <img className="countries" src={UKingdom} alt={'Curoflow telemedicine platform in United Kingdom'} />
                            <img className="countries" src={Denmark} alt={'Curoflow telemedicine platform in Denmark'} />
                            <img className="countries" src={Sweden} alt={'Curoflow telemedicine platform in Sweden'} />
                            {/* <Img className="countries" src={Nigeria} alt={'Curoflow telemedicine platform in Nigeria'} /> */}
                            <img className="countries" src={Spain} alt={'Curoflow telemedicine platform in Spain'} />
                            <img className="countries" src={Greece} alt={'Curoflow telemedicine platform in Greece'} />
                            <img className="countries" src={ArabEmirates} alt={'Curoflow telemedicine platform in ArabEmirates'} />

                        </div>
                    </div>
                </section>




                <section className='logo-slider'>
                    <Marquee speed={70} pauseOnClick={true}>

                        <img src={Logo1} alt={'Acceptus use telemedicine software for digital healthcare'} />
                        <img src={Logo2} alt={'Accesscare use telemedicine software for digital healthcare'} />
                        <img src={Logo3} alt={'Aleris uses telemedicine software for digital patient care'} />
                        <img src={Logo32} alt={'Älvsjö sexuell hälsa has telemedicine software for digital healthcare'} />
                        <img src={Logo35} alt={' Al-Thiqa Pharmacy uses Curoflow Telemedicine platform'} />

                        {/* <img src={Logo4} alt={'Bauder uses telemedicine software for digital healthcare'} />  */}
                        <img src={Logo5} alt={'Diagnostiskt centrum hud uses telehealth software'} />
                        <img src={Logo36} alt={'Direkt Hälsa uses Curoflow Telemedicine platform'} />
                        <img src={Logo6} alt={'Dyslexia test centre use telemedicine software for digital healthcare'} />
                        <img src={Logo38} alt={'e-doctor uses curoflow telemedicine platform'} />

                        <img src={Logo7} alt={'Ekenhälsan uses telemedicine software for digital healthcare'} />
                        <img src={Logo8} alt={'Endbright use telemedicine software for digital healthcare'} />
                        <img src={Logo9} alt={'Endogyn uses telemedicine software at OB GYN clinic'} />
                        <img src={Logo10} alt={'Erektify uses telemedicine software for digital healthcare'} />


                        <img src={Logo11} alt={'EUmedical uses telemedicine software for digital patient management'} />
                        <img src={Logo12} alt={'ExpertQ uses telemedicine software for digital healthcare'} />
                        <img src={Logo13} alt={'Femcare uses telemedicine software for digital healthcare'} />
                        <img src={Logo14} alt={'Future clinic trials provide digital healthcare through telemedicine software'} />
                        {/* <img src={Logo15} alt={'Gårdsten vårdcentral use telemedicine software for digital healthcare'} /> */}
                        <img src={Logo16} alt={'Gävle läkarmottagning uses telemedicine software for digital specialist care'} />
                        <img src={Logo17} alt={'Gynhälsan uses telemedicine software for digital fertility care'} />
                        {/* <img src={Logo18} alt={'Happy healing hut uses telemedicine software for digital healthcare'} /> */}
                        <img src={Logo19} alt={'Holistal uses telemedicine software for digital healthcare'} />
                        <img src={Logo20} alt={'Humana uses Curoflow telemedicine software for digital patient care '} />

                        <img src={Logo39} alt={'Kognitiva teamet uses Curoflow telemedicine platform '} />

                        <img src={Logo22} alt={'Liwia uses telemedicine software for digital primary care'} />
                        <img src={Logo23} alt={'Medect use telemedicine software for digital healthcare'} />
                        <img src={Logo33} alt={'MTD Online uses Curuflow telemedicine platform'} />
                        <img src={Logo24} alt={'Migränhjälpen uses telemedicine software for digital specialist care'} />
                        <img src={Logo21} alt={'Myya use telemedicine software for digital healthcare'} />
                        <img src={Logo25} alt={'Novius ögonklinik uses telemedicine software for digital specialist care'} />
                        <img src={Logo26} alt={'Optifit uses telemedicine software for weight loss treatments'} />
                        <img src={Logo27} alt={'Sapphire clinics uses telemedicine software for digital patient communication'} />
                        <img src={Logo28} alt={'Speciallaege use telemedicine software for digital healthcare'} />
                        <img src={Logo29} alt={'Sportive use telemedicine software for digital healthcare'} />



                        <img src={Logo37} alt={'Vital Vårdcentral Malmö uses Curoflow Telemedicine platform'} />
                        {/* <img src={Logo30} alt={'Velora use telemedicine software for digital healthcare'} />  */}
                        <img src={Logo34} alt={'Vitaminkliniken uses Curuflow telemedicine platform'} />
                        <img src={Logo31} alt={'Wellgo uses telemedicine software for weight loss treatments'} />




                    </Marquee>
                </section>



                {/**end new section 2 home page */}


                {/* 2 section home page*/}



                {/* 
                <section className={`home2 ${this.state.showHome2 ? 'home2Visible' : ''}`}>
                    <div className='container-curoflow'>
                        <div className='launch'>

                            <div className='launch-content'>
                                <h2>{homePage && homePage.titleBelowFirstSlider && homePage.titleBelowFirstSlider[this.props.lang]}</h2>
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage, 'descriptionBelowFirstSlider', this.props.lang) }}></div>

                                <button onClick={() => {
                                    this.setState({
                                        supportForm1: true
                                    })
                                }}>Boka ett lunchmöte</button>
                            </div>
                            <div className='launch-content1'>
                                <Img src={API_ENDPOINT + (homePage && homePage.imageBelowFirstSlider)} alt={(homePage && homePage.imageBelowFirstSlider?.replace('/uploads/', '').replace(/_/g, ' ')?.slice(0, -4))} />
                            </div>
                        </div>



                    </div>
                </section> */}







                {/* end 2 section home page*/}

                {/* 2 section home page8888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}


                {/* <section className={`home2 ${this.state.showHome2 ? 'home2Visible' : ''}`}>
                    <div className='container-curoflow'>
                        <div className='launch'>

                            <div className='launch-content'>
                                <h2>{homePageTestSection2.titleBelowFirstSlider[this.props.lang]}</h2>
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(homePageTestSection2, 'descriptionBelowFirstSlider', this.props.lang) }}></div>

                                <button onClick={() => {
                                    this.setState({
                                        supportForm1: true
                                    })
                                }}>Boka ett lunchmöte</button>
                            </div>
                            <div className='launch-content1'>
                                <Img src={ImageSection2} alt = { 'Lunch med Curoflow for digitala vardtjanster' }/>
                            </div>
                        </div>



                    </div>
                </section> */}




                {/* end 2 section home page888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}




                <section key={'1d6rdr6'} id='test' className='homeSlideSection'>
                    <div className='container-curoflow content-title'>
                        <h1>{homePage && homePage.titleAboveSecondSlider && homePage.titleAboveSecondSlider[this.props.lang]}</h1>
                        <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage, 'descriptionAboveSecondSlider', this.props.lang) }}></div>
                    </div>


                    <div className='tab-slider'>
                        {
                            homePage?.secondSliderITems ?


                                homePage?.secondSliderITems?.map?.((item, id) => {
                                    return (
                                        <div className={this.state.activeSliderIndex === id ? 'active-slider' : ''} key={item?.tabname?.[this.props.lang] || id} onClick={() => {
                                            this.slider2.slickGoTo(id)
                                        }}>
                                            <button >
                                                {item?.tabname?.[this.props.lang]}
                                            </button>

                                        </div>
                                    )
                                }


                                )



                                :
                                null
                        }
                    </div>




                    {
                        typeof window !== 'undefined' &&
                            homePage?.secondSliderITems?.length ? <Slider key='first-slider-asdf' beforeChange={(_, _id) => this.setState({ activeSliderIndex: _id })} ref={slider => (this.slider2 = slider)}
                                {...{
                                    infinite: true,
                                    speed: 500,
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    swipe: false,
                                    // autoplaySpeed: 8000,
                                }}>

                            {
                                homePage?.secondSliderITems?.map?.((item, id) => {
                                    return (
                                        <div key={item?.imageOrVideo || id} className='slider-content'>

                                            <div className='slider-text'>
                                                <h3>{item?.title?.[this.props.lang]}</h3>
                                                <div className='subtitle-slider' dangerouslySetInnerHTML={{ __html: Object.translate(item, 'description', this.props.lang) }}></div>


                                                <button className='slider-button' onClick={() => {
                                                    this.props[0].history.push(item?.link)
                                                }}>{item?.btnText?.[this.props.lang]}
                                                </button>



                                            </div>
                                            <Img lazy={true} src={API_ENDPOINT + (typeof item?.imageOrVideo === 'string' ? item.imageOrVideo : item.imageOrVideo?.[this.props.lang])} alt={(typeof item?.imageOrVideo === 'string' ? item?.imageOrVideo.replace('/uploads/', '').replace(/_/g, ' ')?.slice(0, -4) : item?.imageOrVideo?.[this.props.lang]?.replace('/uploads/', '').replace(/_/g, ' ')?.slice(0, -4))} />
                                        </div>
                                    )
                                }


                                )
                            }
                        </Slider>
                            :
                            null
                    }




                </section>

                <div className='mobile-slider'>
                    {homePage?.secondSliderITems ? <div>
                        {homePage?.secondSliderITems?.map?.((item, id) => <div className='mobile-slider-content' key={item?.tabname?.[this.props.lang] || id}>


                            <button onClick={() => {
                                this.setState(prev => ({ ...prev, slidesOnMobile: (prev?.slidesOnMobile && prev?.slidesOnMobile === item?.tabname?.[this.props.lang]) ? null : item?.tabname?.[this.props.lang] }))
                            }} className='title-mobile'>{item?.tabname?.[this.props.lang]}</button>



                            <Collapse isOpen={this.state.slidesOnMobile && this.state.slidesOnMobile === item?.tabname?.[this.props.lang]}>
                                <div className='image-content'>
                                    <Img lazy={true} src={API_ENDPOINT + (typeof item?.imageOrVideo === 'string' ? item.imageOrVideo : item.imageOrVideo?.[this.props.lang])} alt={(typeof item?.imageOrVideo === 'string' ? item?.imageOrVideo.replace('/uploads/', '').replace(/_/g, ' ')?.slice(0, -4) : item?.imageOrVideo?.[this.props.lang]?.replace('/uploads/', '').replace(/_/g, ' ')?.slice(0, -4))} />
                                </div>

                                <div className='slider-text'>
                                    <h3>{item?.title?.[this.props.lang]}</h3>
                                    <div className='subtitle-slider' dangerouslySetInnerHTML={{ __html: Object.translate(item, 'description', this.props.lang) }}></div>


                                    <button className='slider-button' onClick={() => {
                                        this.props[0].history.push(item?.link)
                                    }}>{item?.btnText?.[this.props.lang]}
                                    </button>



                                </div>
                            </Collapse>
                        </div>)}
                    </div> : null}
                </div>


                {/* 4 section home page*/}

                <section className='home4'>
                    <div className='container-curoflow'>
                        <h1>{homePage && homePage.titleBelowSecondSlider && homePage.titleBelowSecondSlider[this.props.lang]}</h1>
                        <div className='titlet' dangerouslySetInnerHTML={{ __html: Object.translate(homePage, 'descriptionBelowSecondSlider', this.props.lang) }}></div>
                    </div>

                    <div className='container-curoflow platform'>
                        <div className='col-curoflow-lg-3'>
                            <div className='box-content'>
                                <Isvg src={API_ENDPOINT + homePage?.section4?.image1} alt='padlock' />
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage?.section4, 'text1', this.props.lang) }} />
                            </div>
                        </div>
                        <div className='col-curoflow-lg-3'>
                            <div className='box-content'>
                                <Isvg src={API_ENDPOINT + homePage?.section4?.image2} alt='padlock' />
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage?.section4, 'text2', this.props.lang) }} />
                            </div>
                        </div>
                        <div className='col-curoflow-lg-3'>
                            <div className='box-content'>
                                <Isvg src={API_ENDPOINT + homePage?.section4?.image3} alt='padlock' />
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage?.section4, 'text3', this.props.lang) }} />
                            </div>
                        </div>
                        <div className='col-curoflow-lg-3'>
                            <div className='box-content'>
                                <Isvg src={API_ENDPOINT + homePage?.section4?.image4} alt='padlock' />
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage?.section4, 'text4', this.props.lang) }} />
                            </div>
                        </div>
                    </div>
                    <div className='container-curoflow platform1'>
                        <div className='col-curoflow-lg-3'>
                            <div className='box-content'>
                                <Isvg src={API_ENDPOINT + homePage?.section4?.image5} alt='padlock' />
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage?.section4, 'text5', this.props.lang) }} />
                            </div>
                        </div>
                        <div className='col-curoflow-lg-3'>
                            <div className='box-content'>
                                <Isvg src={API_ENDPOINT + homePage?.section4?.image6} alt='padlock' />
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage?.section4, 'text6', this.props.lang) }} />
                            </div>
                        </div>
                        <div className='col-curoflow-lg-3'>
                            <div className='box-content'>
                                <Isvg src={API_ENDPOINT + homePage?.section4?.image7} alt='padlock' />
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage?.section4, 'text7', this.props.lang) }} />
                            </div>
                        </div>
                        <div className='col-curoflow-lg-3'>
                            <div className='box-content'>
                                <Isvg src={API_ENDPOINT + homePage?.section4?.image8} alt='padlock' />
                                <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage?.section4, 'text8', this.props.lang) }} />
                            </div>
                        </div>
                    </div>

                </section>

                {/* end 4 section home page*/}

                {/* 4 section home page*/}




                {/* 4 end section home page*/}

                {/* 5 section home page*/}


                {/* end 5 section home page*/}

                <section className='home5'>
                    <div className='container-curoflow container-curoflow1'>
                        <div className='home5-title' dangerouslySetInnerHTML={{ __html: Object.translate(homePage.section5, 'title', this.props.lang) }}></div>
                        <div className='home5-subtitle' dangerouslySetInnerHTML={{ __html: Object.translate(homePage.section5, 'subtitle', this.props.lang) }}></div>
                    </div>



                    <button className='home-download-btn' onClick={() => {
                        let _name = 'pdfswe';
                        if (this.props.lang === 'en') _name = 'pdfen';
                        let a = document.createElement('a');
                        a.href = (API_ENDPOINT + (homePage?.section5?.[_name]?.file));
                        a.download = true;
                        a.target = '_blank';
                        document.body.appendChild(a);
                        a.click?.();
                        a.remove?.()
                    }}> {"Download product sheet".translate(this.props.lang)} </button>


                    <div className='container-curoflow'>
                        <div className='col-curoflow-lg-6' style={{ backgroundColor: '#DFE5E2', borderRadius: '8px' }}>
                            <Img lazy={true} src={API_ENDPOINT + homePage?.section5?.image1} alt={(homePage?.section5?.image1.replace('/uploads/', '').replace(/_/g, ' ')?.slice(0, -4))} />
                            <div className='box-content'>

                                <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage.section5, 'titleAndSubtitle', this.props.lang) }}></div>
                            </div>

                        </div>

                        <div className='col-curoflow-lg-6' style={{ backgroundColor: '#DFE5E2', borderRadius: '8px' }}>
                            <Img lazy={true} src={API_ENDPOINT + homePage?.section5?.image2} alt={(homePage?.section5?.image2.replace('/uploads/', '').replace(/_/g, ' ')?.slice(0, -4))} />
                            <div className='box-content'>

                                <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage.section5, 'titleAndSubtitle1', this.props.lang) }}></div>
                            </div>

                        </div>
                    </div>

                </section>





                <section className='home6'>

                    <Waypoint onEnter={() => {
                        if (!this.waypointCBAlreadyCalled && homePage?.section6) {
                            this.waypointCBAlreadyCalled = true;

                            this.increment()
                            this.increment1()
                            this.increment2();


                        } else return;
                    }}>

                        <div style={{ height: 10, visibility: 'hidden' }} />

                    </Waypoint>
                    {/* : null } */}

                    <div className='container-curoflow'>
                        <div className='title' dangerouslySetInnerHTML={{ __html: Object.translate(homePage.section6, 'title', this.props.lang) }}></div>
                        <div className='subtitle' dangerouslySetInnerHTML={{ __html: Object.translate(homePage.section6, 'subtitle', this.props.lang) }}></div>
                    </div>
                    <div className='home6-content'>

                        <div>


                            <div className='content-hom6'><h1>{this.state._first} %</h1>   <div className='content-hom6' dangerouslySetInnerHTML={{ __html: Object.translate(homePage.section6, 'description1', this.props.lang) }}></div> </div>

                        </div>
                        <div>

                            <div className='content-hom6'><h1>{this.state._second} %</h1>     <div className='content-hom6' dangerouslySetInnerHTML={{ __html: Object.translate(homePage.section6, 'description2', this.props.lang) }}></div> </div>

                        </div>
                        <div>

                            <div className='content-hom6'><h1>{this.state._third} %</h1>          <div className='content-hom6 last' dangerouslySetInnerHTML={{ __html: Object.translate(homePage.section6, 'description3', this.props.lang) }}></div>  </div>
                        </div>
                    </div>


                </section>






                <section className='home7'>
                    <div className='container-curoflow'>
                        <div className='title' dangerouslySetInnerHTML={{ __html: Object.translate(homePage.section7, 'title', this.props.lang) }}></div>
                        <div className='subtitle' dangerouslySetInnerHTML={{ __html: Object.translate(homePage.section7, 'description', this.props.lang) }}></div>
                        <Link className="customer-link" to='/kunders-feedback-pa-Curoflow-digital-vardplattform' lang={this.props.lang}>{'Read more success stories'.translate(this.props.lang)}</Link>
                    </div>


                    {typeof window !== 'undefined' &&
                        homePage?.thirdSliderItems?.length ? <Slider ref={slider => (this.slider5 = slider)}
                            {...{
                                dots: true,
                                infinite: true,
                                speed: 1500,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                autoplaySpeed: 8000,
                                swipe: false,
                                responsive: [
                                    {
                                        breakpoint: 576,
                                        settings: {
                                            swipe: true,
                                            dots: true
                                        },
                                    }
                                ]
                            }}>

                        {
                            homePage?.thirdSliderItems?.map?.((item, id) => {
                                return (
                                    <div key={item?.imageOrVideo || id} className='content-slider7'>

                                        <div className='main-img-slider-content'>
                                            <Img lazy={true} src={API_ENDPOINT + (item?.imageOrVideo)} alt={(item?.imageOrVideo.replace('/uploads/', '').replace(/_/g, ' ')?.slice(0, -4))} className='main-img-slider' />
                                        </div>

                                        <div className='content-slider2'>
                                            <Img lazy={true} src={API_ENDPOINT + (item?.imageLogo)} alt={(item?.imageLogo.replace('/uploads/', '').replace(/_/g, ' ')?.slice(0, -4))} className='main-logo-slider' />
                                            <div className='subtitle-slider' dangerouslySetInnerHTML={{ __html: Object.translate(item, 'description', this.props.lang) }}></div>
                                        </div>



                                    </div>
                                )
                            }


                            )
                        }


                    </Slider>
                        :
                        null
                    }

                </section>











                <section key={'1d6rdr6hjdssa'} id='test' className={`homeSlideSection hmSld ${this.props.lang === 'se' ? 'homeSlideSection1' : ''}`}>
                    <div className='container-curoflow content-title'>
                        <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage.section8, 'title', this.props.lang) }}></div>
                        <div dangerouslySetInnerHTML={{ __html: Object.translate(homePage.section8, 'description', this.props.lang) }}></div>
                    </div>


                    <div className='tab-slider tab-slider-last'>
                        {
                            homePage?.lastSliderITems ?


                                homePage?.lastSliderITems?.map?.((item, id) => {
                                    return (
                                        <div className={this.state.activeSliderIndex2 === id ? 'active-slider' : ''} key={item?.tabname?.[this.props.lang] || id} onClick={() => {
                                            this.slider3.slickGoTo(id)
                                        }}>
                                            <button >
                                                {item?.tabname?.[this.props.lang]}
                                            </button>

                                        </div>
                                    )
                                }


                                )



                                :
                                null
                        }
                    </div>




                    {
                        homePage?.lastSliderITems?.length ? <Slider key='second-slider-asdf' beforeChange={(_, _id) => this.setState({ activeSliderIndex2: _id })} ref={slider => (this.slider3 = slider)}
                            {...{
                                infinite: true,
                                speed: 500,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                swipe: false
                                // autoplaySpeed: 8000,
                            }}>

                            {
                                homePage?.lastSliderITems?.map?.((item, id) => {
                                    return (
                                        <div key={item?.imageOrVideo || id} className='slider-content'>

                                            <div className='slider-text'>
                                                <h3>{item?.title?.[this.props.lang]}</h3>
                                                <div className='subtitle-slider' dangerouslySetInnerHTML={{ __html: Object.translate(item, 'description', this.props.lang) }}></div>


                                                <button className='slider-button' onClick={() => {
                                                    this.props[0].history.push(item?.link)
                                                }}>{item?.btnText?.[this.props.lang]}
                                                </button>



                                            </div>
                                            <Img lazy={true} src={API_ENDPOINT + (item?.imageOrVideo)} alt={(item?.imageOrVideo.replace('/uploads/', '').replace(/_/g, ' ')?.slice(0, -4))} />
                                        </div>
                                    )
                                }


                                )
                            }
                        </Slider>
                            :
                            null
                    }




                </section>

                <div className='mobile-slider'>
                    {homePage?.lastSliderITems ? <div>
                        {homePage?.lastSliderITems?.map?.((item, id) => <div className='mobile-slider-content' key={item?.tabname?.[this.props.lang] || id}>


                            <button onClick={(e) => {
                                this.setState(prev => ({ ...prev, slidesOnMobile1: (prev?.slidesOnMobile1 && prev?.slidesOnMobile1 === item?.tabname?.[this.props.lang]) ? null : item?.tabname?.[this.props.lang] }));

                            }} className='title-mobile'>{item?.tabname?.[this.props.lang]}</button>



                            <Collapse isOpen={this.state.slidesOnMobile1 && this.state.slidesOnMobile1 === item?.tabname?.[this.props.lang]}>
                                <div className='image-content'>
                                    <Img lazy={true} src={API_ENDPOINT + (item?.imageOrVideo)} alt={(item?.imageOrVideo.replace('/uploads/', '').replace(/_/g, ' ')?.slice(0, -4))} />
                                </div>

                                <div className='slider-text'>
                                    <h3>{item?.title?.[this.props.lang]}</h3>
                                    <div className='subtitle-slider' dangerouslySetInnerHTML={{ __html: Object.translate(item, 'description', this.props.lang) }}></div>


                                    <button className='slider-button' onClick={() => {
                                        this.props[0].history.push(item?.link)
                                    }}>{item?.btnText?.[this.props.lang]}
                                    </button>



                                </div>
                            </Collapse>
                        </div>)}
                    </div> : null}
                </div>
                {this.props.lang === 'se' ? <ContactForm title={homePage?.section9?.title?.[this.props.lang]} text={homePage.section9?.description?.[this.props.lang]} class={'grey'} justShowForm={true} lang='se' onSubmit={this.sendMessage1} contactFormError={this.state.contactFormError} contactFormMessageError={this.state.contactFormMessageError} /> : <ContactForm title={homePage?.section9?.title?.[this.props.lang]} text={homePage.section9?.description?.[this.props.lang]} class={'grey'} justShowForm={true} lang='en' onSubmit={this.sendMessage1} contactFormError={this.state.contactFormError} contactFormMessageError={this.state.contactFormMessageError} />}


                {/* <ContactForm title={homePage?.section9?.title?.[this.props.lang]} text={homePage.section9?.description?.[this.props.lang]} class={'grey'} justShowForm={true} lang={this.props.lang}  onSubmit={this.sendMessage1} contactFormError={this.state.contactFormError} contactFormMessageError={this.state.contactFormMessageError} /> */}



            </div>
        );
    }
}

export default Page(HomePage);

