export const langs = {
    "se": {
        'Save': 'Spara',
        'off / on': 'av / på',
        'To optimize your use of our website, Curoflow uses necessary cookies. If you agree, we also use statistics & marketing cookies in order to analyze website traffic and offer more relevant communication. The data is shared with the cookie provider and you can revoke your consent at any time.': 'För att optimera ditt användande av vår webbplats använder Curoflow nödvändiga cookies. Om du samtycker använder vi även statistik- & marknadsföringscookies i syfte att analysera webbplatstrafik och erbjuda mer relevant kommunikation. Datan delas med cookieleverantören och du kan återkalla ditt samtycke när som helst.',
        'Necessary cookies': 'Nödvändiga cookies',
        'Statistics Cookies2': 'Statistikcookies',
        'Marketing cookies': 'Marknadsföringscookies',
        'Change settings': 'Inställningar',
        'Accept all': 'Acceptera alla',
        'Upload a valid file format (JPG, PNG, or WebP) with a maximum size of 10MB': 'Bifoga giltigt filformat (JPG, PNG eller WebP) med en maximal storlek på 10 MB',
        'Button 1': 'Knapp  1',
        'Button 2': 'Knapp  2',
        'Button 3': 'Knapp  3',
        'Button 4': 'Knapp  4',
        'Create your app': 'Skapa din app',
        'Back': 'Tillbaka',
        'How many buttons do you need?': 'Hur många knappar behöver ni?',
        "Step 3. Buttons": "Steg 3. Knappar",
        "Hex code secondary color": "Hex-kod sekundär färg",
        "Hex code primary color": "Hex-kod primär färg",
        'Step 2. Colors': 'Steg 2. Färger',
        'Attach image': 'Bifoga bild',
        'Text on button 1': 'Text på knapp 1',
        'Text on button 2': 'Text på knapp 2',
        'Text on button 3': 'Text på knapp 3',
        'Text on button 4': 'Text på knapp 4',
        'Attach logo': 'Bifoga logga',
        'Image': 'Bild',
        'Logotype': 'Logotyp',
        "Step 1. Attachments": "Steg 1. Bilagor",
        "Insights": "Insikter",
        'NewCompany': 'Organisation',
        'newTitle': 'Jobbtitel',
        "GDPR compliance": "GDPR",
        "Our platform": "Vår plattform",
        'More news': 'Fler nyheter',
        'More articles': 'Fler artiklar',
        "Organisation": "Organisation",
        'Please fill in your work email': 'Var god och fyll i din jobbmail',
        'Please use at least 25 letters': 'Var vänlig och använd minst 25 tecken',
        'Name': 'Ditt namn',
        'Job title': 'Jobbtitel',
        'Work email': 'Jobbmail',
        'Telefonnumer': 'Telefon',
        'Land': 'Land',
        'Read more success stories': 'Läs fler kundberättelser',
        'Articles': 'Artiklar',
        "Company": 'Företaget',
        "Resources": "Kunskapsbank",
        'Telemedicine platform': 'Vårdplattformen',
        "Subject line": "Ämnesrad",
        'Please fill out the form to send us an email': 'Fyll i formuläret för att skicka ett e-postmeddelande till oss',
        'Contact home page': 'Kontakt',
        'Learn more': 'Läs mer',
        'Here you find articles, white papers, guides etc. about telemedicine and the Curoflow telehealth platform.': 'Här finner ni artiklar, white papers, guider etc. om digifysisk vård och Curoflow digital vårdplattform.',
        'Fill in the required information and try again': "Fyll i nödvändiga uppgifter och försök igen",
        "Next": "Nästa",
        'Previous': 'Föregående',
        'Read more': 'Läs mer',
        'Book a demo': 'Boka demo',
        "See more of our coworkers": "Se fler av våra kollegor",
        "Book a demo of Curoflow": "Boka en demo av Curoflow",
        "Contact our support": "Kontakta vår support",
        "Download product sheet": "Ladda ner produktblad",
        "Podcast": "Podd",
        "Career": "Karriär",
        'thank-you': "tack",
        "To the homepage": "Till startsidan",
        "For urgent matters, call +46 (0)10-750 06 55": "För brådskande ärenden, ring 010-750 06 55",
        "Our office hours are Monday - Friday ​​08.30 - 17.00, with the exception of public holidays": "Våra ordinarie öppettider är helgfria vardagar, kl. ​​08.30 - 17.00",
        "Your message has been received and we will get back to you shortly": "Ditt meddelande har tagits emot och vi återkommer till dig inom kort",
        "Thank you for contacting us": "Tack för att du kontaktar oss",
        "Social media": "Sociala medier",
        "Contact our support": "Kontakta support",
        "To newsroom": "Till nyhetsrummet",
        "Carasent is a software company that develops, sells and delivers e-health services to the Nordic healthcare sector. The business is based on the vision of patient-focused care with minimised administration. The core of the product catalogue is the proprietary web-based medical record system Webdoc": "Carasent är ett mjukvarubolag som utvecklar, säljer och levererar e-hälsotjänster till hälso- och sjukvården i Norden. Verksamheten bygger på visionen om en patientfokuserad vård med minimerad administration. Kärnan i produktkatalogen är det egenutvecklade och webbaserade journalsystemet Webdoc",
        "About Carasent Sverige AB": "Om Carasent Sverige AB",
        "About Curoflow Technology AB": "Om Curoflow Technology AB",
        "Curoflow is a platform company providing one of Europe's most comprehensive cloud services for digital patient management, status and history capture and secure patient meetings. Curoflow's mission is to dramatically reduce healthcare queues, simplify the work of healthcare professionals and make tomorrow's digital tools available in a smart way": "Curoflow är ett plattformsbolag som tillhandahåller en av Europas mest omfattande molntjänster för digital patienthantering, status- och anamnesupptagning och säkra patientmöten. Curoflow har som mission att kraftigt reducera vårdköer, förenkla vårdpersonalens arbete och tillgängliggöra morgondagens digitala verktyg på ett klokt sätt",
        'For more info, contact': 'För mer info, kontakta',
        'Jag godkänner Curoflows': 'Jag godkänner Curoflows',
        'Sticka': 'Sticka',
        "Namn": "Namn",
        "010 - 750 06 55": "010 - 750 06 55",
        "I accept Curoflow’s": "Jag godkänner Curoflows",
        "Curoflow telemedicine platform is a registered medical device under Regulation (EU) 2017/745 of the European Parliament and of the Council on medical devices (the MDR). All data is encrypted, stored and processed in accordance with the Swedish Patient Data Act (Sw. Patientdatalagen) and the EU General Data Protection Regulation (the GDPR)": "Curoflows plattform är en registrerad medicinteknisk produkt i enlighet med EU Förordningen om medicintekniska produkter (MDR) (EU) 2017/745. All data krypteras, lagras och behandlas enligt patientdatalagen (PDL) och EUs dataskyddsförordning (GDPR)",
        "privacy policy": "integritetspolicy",
        "Privacy policy": "Integritetspolicy",
        "I accept the website": "Jag godkänner Curoflows",
        "Subscribe": "Prenumerera",
        "Subscribe to our newsletter": "Prenumerera på vårt nyhetsbrev",
        "The latest": "Det senaste",
        "Your email": "Din e-mail",
        "Telephone": "Telefon",
        "Email": "E-mail",
        "Ange en giltig e-postadress": "Ange en giltig e-postadress",
        "Our solutions": "Våra lösningar",
        "We assist": "Vi hjälper",
        "About Us": "Om oss",
        "Customers tell us": "Kunder berättar",
        "Here we collect press releases and major news about Curoflow. See also our": "Här samlar vi pressreleaser och större nyheter om Curoflow. Se även vår",
        "Stäng": "Stäng",
        "Tack för att du anmält dig till vårt nyhetsbrev": "Tack för att du anmält dig till vårt nyhetsbrev",
        "Kontakta support": "Kontakta support",
        "Karriärsida": "Karriärsida",
        "Mån – Fre": "Mån – Fre",
        "Öppettider:": "Öppettider:",
        "E-mail adress": "E-mail adress",
        "Boka en demo": "Book a demo",
        "Contact": "Kontakta oss",
        "for more news": "för fler nyheter",
        "Vår Lösning": "Vår Lösning",
        "News": "Nyheter",
        "Karriär": "Karriär",
        "Friktionsfria vårdkedjor": "Friktionsfria vårdkedjor",
        "Skapa er egen digitala mottagning med Curoflow. Vi kan digital vård, teknik och datasäkerhet, ni kan och känner er verksamhet och era patienter bäst. Tillsammans möjliggör vi automatiserade processer, effektiva vårdflöden och en patientnära vård.": "Skapa er egen digitala mottagning med Curoflow. Vi kan digital vård, teknik och datasäkerhet, ni kan och känner er verksamhet och era patienter bäst. Tillsammans möjliggör vi automatiserade processer, effektiva vårdflöden och en patientnära vård.",
        "Kontakta oss": "Kontakta oss",
        "Contact us": "Kontakta oss",
        "Vår lösning är": "Vår lösning är",
        "Er lösning": "Er lösning",
        "Genom att skapa flerdimensionella vårdsystem skapar vi unika förutsättningar för just er verksamhet. Berätta för oss vad ni behöver så tar vi fram lösningen. Och just det – det är alltid ert varumärke som syns och finns. Vi stöttar i bakgrunden.": "Genom att skapa flerdimensionella vårdsystem skapar vi unika förutsättningar för just er verksamhet. Berätta för oss vad ni behöver så tar vi fram lösningen. Och just det – det är alltid ert varumärke som syns och finns. Vi stöttar i bakgrunden.",
        "Er digitala mottagning": "Er digitala mottagning",
        "Med stor vikt på enkelhet/användarvänlighet blir tekniken ett hjälpmedel när ni möter patienten. Plattformens lättanvända funktioner och automatiserade processer sätter patienten och vårdgivandet i centrum samtidigt som ni sparar tid.": "Med stor vikt på enkelhet/användarvänlighet blir tekniken ett hjälpmedel när ni möter patienten. Plattformens lättanvända funktioner och automatiserade processer sätter patienten och vårdgivandet i centrum samtidigt som ni sparar tid.",
        "Vision E-hälsa 2025": "Vision E-hälsa 2025",
        "Av läkare och sjuksköterskor är positiva till användande av digital service i välfärden": "Av läkare och sjuksköterskor är positiva till användande av digital service i välfärden",
        "Av alla invånare är positiva till vård, konsultation och behandling på distans med stöd av digitala lösningar": "Av alla invånare är positiva till vård, konsultation och behandling på distans med stöd av digitala lösningar",
        "Upplever det som positivt att kommuner och regioner kommunicerar och erbjuder service digitalt": "Upplever det som positivt att kommuner och regioner kommunicerar och erbjuder service digitalt",
        "Med patienten i fokus": "Med patienten i fokus",
        "Vi hjälper er att framtidssäkra er verksamhet och öka patientnöjdheten. Digitala verktyg som ett komplement till en väl fungerande verksamhet.": "Vi hjälper er att framtidssäkra er verksamhet och öka patientnöjdheten. Digitala verktyg som ett komplement till en väl fungerande verksamhet.",
        "av": "av",
        "Patienter skulle välja digitala hjälpmedel för grundläggande vårdtjänster men även för specialistvård": "Patienter skulle välja digitala hjälpmedel för grundläggande vårdtjänster men även för specialistvård",
        "Patienter säger att de gärna byter till en vårdgivare som erbjuder digital service framför en som inte gör det": "Patienter säger att de gärna byter till en vårdgivare som erbjuder digital service framför en som inte gör det",
        "Patienter säger att en positiv digital upplevelse stärker deras fortroende för vårdgivaren": "Patienter säger att en positiv digital upplevelse stärker deras fortroende för vårdgivaren",
        "För att vårdgivare ska klara av att effektivisera och möta det ökade behovet av säker digitalisering i vården, ser jag Curoflow som en självklar del av lösningen.": "För att vårdgivare ska klara av att effektivisera och möta det ökade behovet av säker digitalisering i vården, ser jag Curoflow som en självklar del av lösningen.",
        "- Sören Olofsson, Tidigare regeringens särskilde utredare inom hälso- och sjukvård och regiondirektör SLL, samt Region Skåne": "- Sören Olofsson, Tidigare regeringens särskilde utredare inom hälso- och sjukvård och regiondirektör SLL, samt Region Skåne",
        "Källa": "Källa",
        "Upptäck Lösningar": "Upptäck Lösningar",
        "Digitala kliniken": "Digitala kliniken",
        "Curoflow IVF": "Curoflow IVF",
        "Intelligent remittering": "Intelligent remittering",
        "Er digitala mottagning. Chatt, video, anamnesupptagning och mycket mer.": "Er digitala mottagning. Chatt, video, anamnesupptagning och mycket mer.",
        "Digitalt vårdflöde särskilt utvecklat för en kvalitativ IVF-process.": "Digitalt vårdflöde särskilt utvecklat för en kvalitativ IVF-process.",
        "Upp till 99% godkända remisser. Revolutionerande lösning för en smart och smidig remitteringskedja.": "Upp till 99% godkända remisser. Revolutionerande lösning för en smart och smidig remitteringskedja.",
        "Läs mer": "Läs mer",
        "Om Oss": "Om Oss",
        "En i grunden ideell tjänst skapad av vårdpersonal och tekniska utvecklare, det var så vår resa började. Idag är vår mission att kraftigt reducera vårdköer, förenkla vårdpersonalens arbete och tillgängliggöra morgondagens digitala verktyg på ett klokt sätt.": "En i grunden ideell tjänst skapad av vårdpersonal och tekniska utvecklare, det var så vår resa började. Idag är vår mission att kraftigt reducera vårdköer, förenkla vårdpersonalens arbete och tillgängliggöra morgondagens digitala verktyg på ett klokt sätt.",
        "För partners": "För partners",
        "Vårdgivare behöver snabbt anpassa sig för att möta det ökade behovet av effektivisering och digitalisering utan att kompromissa på säkerhet och kvalitet. Därför skapade vi Curoflow – den intelligenta och multidimensionella vårdplattformen för modern vård. Vill du vara med och möjliggöra framtidens vård?": "Vårdgivare behöver snabbt anpassa sig för att möta det ökade behovet av effektivisering och digitalisering utan att kompromissa på säkerhet och kvalitet. Därför skapade vi Curoflow – den intelligenta och multidimensionella vårdplattformen för modern vård. Vill du vara med och möjliggöra framtidens vård?",
        "Kontakta oss så berättar vi mer!": "Kontakta oss så berättar vi mer!",
        "Kontakt": "Kontakt",
        "Vill du veta mer om hur vi tillsammans möjliggör digitalt effektiva lösningar för er verksamhet och era patienter? Ta kontakt med oss idag så berättar vi mer.": "Vill du veta mer om hur vi tillsammans möjliggör digitalt effektiva lösningar för er verksamhet och era patienter? Ta kontakt med oss idag så berättar vi mer.",
        "E-mail": "E-mail",
        "Meddelande": "Meddelande",
        "Genom att skicka in din E-mailadress godkänner du Curoflows": "Genom att skicka in din E-mailadress godkänner du Curoflows",
        "integritetspolicy": "integritetspolicy",
        "Skicka": "Skicka",
        "Du kan aven kontakta oss via telefon:": "Du kan aven kontakta oss via telefon:",
        "Navigera": "Navigera",
        "Hem": "Hem",
        "Vår lösning": "Vår lösning",
        "Om oss": "Om oss",
        "För partners": "För partners",
        "Information": "Information",
        "Integritetspolicy": "Integritetspolicy",
        "Cookies": "Cookies",
        "Språk": "Språk",
        "Nyhetsbrev": "Nyhetsbrev",
        "Håll dig uppdaterad på nyheter och pressutskick:": "Håll dig uppdaterad på nyheter och pressutskick:",
        "Välkommen till ert nya jag": "Välkommen till ert nya jag",
        "Att göra er verksamhet digital eller digifysisk behöver inte vara svårt, tidskrävande eller dyrt. Vi kom från den fysiska vården, vi blev digitala och vi har nu utvecklat möjligheten för fler att ta klivet. Vi hjälper er hela vägen, från start till för alltid.": "Att göra er verksamhet digital eller digifysisk behöver inte vara svårt, tidskrävande eller dyrt. Vi kom från den fysiska vården, vi blev digitala och vi har nu utvecklat möjligheten för fler att ta klivet. Vi hjälper er hela vägen, från start till för alltid.",
        "Kontakta oss för en introduktion": "Kontakta oss för en introduktion",
        "Varför välja Curoflow?": "Varför välja Curoflow?",
        "Vi kan": "Vi kan",
        "Funktioner": "Funktioner",
        "för vården": "för vården",
        "Lätt att": "Lätt att",
        "komma igång": "komma igång",
        "Vi lagrar och hanterar alla personuppgifter och all persondata i Sverige och EU. Till skillnad från majoriteten av leverantörer som är beroende av tredjepartslösningar utanför EU/EES.": "Vi lagrar och hanterar alla personuppgifter och all persondata i Sverige och EU. Till skillnad från majoriteten av leverantörer som är beroende av tredjepartslösningar utanför EU/EES.",
        "Hur väl uppfyller ni lagen och hur måna är ni om era patienters uppgifter?": "Hur väl uppfyller ni lagen och hur måna är ni om era patienters uppgifter?",
        "Curoflow erbjuder möjlighet till en mängd individuella anpassningar. Allt för att passa er verksamhet och de flöden ni behöver. Tonvikt har lagts på enkelhet och automatisering för att förenkla för både er och era patienter.": "Curoflow erbjuder möjlighet till en mängd individuella anpassningar. Allt för att passa er verksamhet och de flöden ni behöver. Tonvikt har lagts på enkelhet och automatisering för att förenkla för både er och era patienter.",
        "Vi vill att digitalisering ska vara enkelt och utan barriärer. Vi hjälper er att komma igång dagen efter, eller med mer djupgående utbildningar där vi ser till att alla känner sig trygga i funktion och design.": "Vi vill att digitalisering ska vara enkelt och utan barriärer. Vi hjälper er att komma igång dagen efter, eller med mer djupgående utbildningar där vi ser till att alla känner sig trygga i funktion och design.",
        "Vi hjälper er att komma igång": "Vi hjälper er att komma igång",
        "Vill ni veta varför Curoflow troligen är den säkraste vårdplattformen ur ett GDPR-perspektiv? Undrar ni över hur Curoflow ser ut och hur det hade fungerat i er verksamhet? Är ni redo att byta till en leverantör som kommer från vården och förstår era behov? Oavsett vad finns vi här för att svara på era frågor.": "Vill ni veta varför Curoflow troligen är den säkraste vårdplattformen ur ett GDPR-perspektiv? Undrar ni över hur Curoflow ser ut och hur det hade fungerat i er verksamhet? Är ni redo att byta till en leverantör som kommer från vården och förstår era behov? Oavsett vad finns vi här för att svara på era frågor.",
        "Välj ditt behov": "Välj ditt behov",
        "Curoflow plattform": "Curoflow plattform",
        "En komplett lösning för alla verksamheter, oavsett storlek": "En komplett lösning för alla verksamheter, oavsett storlek",
        "Patientchatt": "Patientchatt",
        "Chatt för kollegor": "Chatt för kollegor",
        "Automatisk status - och anamnesupptagning": "Automatisk status - och anamnesupptagning",
        "Intelligent formulärverktyg": "Intelligent formulärverktyg",
        "Schema och kalender": "Schema och kalender",
        "Drop-in": "Drop-in",
        "Tidbok": "Tidbok",
        "Kort och swishbetalning": "Kort och swishbetalning",
        "Digital kvittogenerering": "Digital kvittogenerering",
        "Anpassa utseende och design": "Anpassa utseende och design",
        "Frasbibliotek": "Frasbibliotek",
        "Överför patientärenden": "Överför patientärenden",
        "Medicinska formulär med summering": "Medicinska formulär med summering",
        "Hantering av patient och användarkonton": "Hantering av patient och användarkonton",
        "Integration mot journalsystem": "Integration mot journalsystem",
        "Och mer...": "Och mer...",
        "Curoflow Video": "Curoflow Video",
        "GDPR-säker videochatt som tillval till plattformen": "GDPR-säker videochatt som tillval till plattformen",
        "Lagring av persondata i Sverige": "Lagring av persondata i Sverige",
        "Krypterad informationsöverföring": "Krypterad informationsöverföring",
        "Välj mellan 2, 5 eller 10 + deltagare per samtal": "Välj mellan 2, 5 eller 10 + deltagare per samtal",
        "Bjud in externa parter som ex tolk, anhörig, kollega, ombud": "Bjud in externa parter som ex tolk, anhörig, kollega, ombud",
        "Håll interna videomöten inom verksamheten": "Håll interna videomöten inom verksamheten",
        "BankID-autentisering i samband med inloggning för alla externa deltagare": "BankID-autentisering i samband med inloggning för alla externa deltagare",
        "Passar även andra verksamheter med höga krav på säkerhet": "Passar även andra verksamheter med höga krav på säkerhet",
        "Kan användas för att säkerställa identitet på samtliga deltagare": "Kan användas för att säkerställa identitet på samtliga deltagare",
        "Curoflow IDP": "Curoflow IDP",
        "Ert namn och varumärke på": "Ert namn och varumärke på",
        "BankID": "BankID",
        "SITHS-Inlogg": "SITHS-Inlogg",
        "SMS och mejlutskick": "SMS och mejlutskick",
        "Swish och kortbetalning": "Swish och kortbetalning",
        "Curoflow IVF": "Curoflow IVF",
        "Unikt digitalt flöde särskilt framtagen för IVF-processen": "Unikt digitalt flöde särskilt framtagen för IVF-processen",
        "Särskilt framtagna påminnelsefunktioner": "Särskilt framtagna påminnelsefunktioner",
        "Automatiska kallelser till provtagning, besök, m.m.": "Automatiska kallelser till provtagning, besök, m.m.",
        "Digital dagboksfunktion för patient": "Digital dagboksfunktion för patient",
        "Digital läkemedelsbok med påminnelsefunktion": "Digital läkemedelsbok med påminnelsefunktion",
        "Integrerat mot journalsystem": "Integrerat mot journalsystem",
        "Chatt, video, med mera": "Chatt, video, med mera",
        "Världens första intelligenta remitteringsverktyg": "Världens första intelligenta remitteringsverktyg",
        "300 diagnoser tillgängliga att söka bland": "300 diagnoser tillgängliga att söka bland",
        "Automatiserade beslutsstöd med medicinska riktlinjer": "Automatiserade beslutsstöd med medicinska riktlinjer",
        "Autogenererade frågor och svar": "Autogenererade frågor och svar",
        "Autogenererad remiss": "Autogenererad remiss",
        "Förslag på vårdgivare att remittera till": "Förslag på vårdgivare att remittera till",
        "99% godkända remisser": "99% godkända remisser",
        "Ökad medicinsk säkerhet": "Ökad medicinsk säkerhet",
        "Plattform för patientkommunikation": "Plattform för patientkommunikation",
        "Curoflows plattform för patientkommunikation är dynamisk och skalbar. Den fungerar lika bra om du driver en mottagning med två anställda eller beslutar om hälso- och sjukvård i en hel region. Möjligheten till att digitalisera och effektivera ska inte styras av varken tekniska förkunskaper eller storlek på organisationen. Vi tillgodoser varje kunds specifika behov och tar tillsammans fram lösningar för just er verksamhet.": "Curoflows plattform för patientkommunikation är dynamisk och skalbar. Den fungerar lika bra om du driver en mottagning med två anställda eller beslutar om hälso- och sjukvård i en hel region. Möjligheten till att digitalisera och effektivera ska inte styras av varken tekniska förkunskaper eller storlek på organisationen. Vi tillgodoser varje kunds specifika behov och tar tillsammans fram lösningar för just er verksamhet.",
        "Curoflow video är en säker videokommunikationstjänst som säkerställer att ni efterlever GDPR i er verksamhet. All data lagras, krypteras och hanteras i Sverige vilket gör det möjligt för exempelvis vårdpersonal, jurister eller andra yrkesverksamma med höga krav på säkerhet att genomföra trygga videomöten. Deltagare som ansluter till ett videomöte behöver säkerställa sin identitet genom autentisering med Bank-ID eller SITHS-kort. Deltagare kan ansluta till mötet från surfplatta, dator eller smartphone.": "Curoflow video är en säker videokommunikationstjänst som säkerställer att ni efterlever GDPR i er verksamhet. All data lagras, krypteras och hanteras i Sverige vilket gör det möjligt för exempelvis vårdpersonal, jurister eller andra yrkesverksamma med höga krav på säkerhet att genomföra trygga videomöten. Deltagare som ansluter till ett videomöte behöver säkerställa sin identitet genom autentisering med Bank-ID eller SITHS-kort. Deltagare kan ansluta till mötet från surfplatta, dator eller smartphone.",
        "Kommer Hösten 2021": "Kommer Hösten 2021",
        "Med Curoflow-IVF digitaliserar vi en mer eller mindre helt analog process. Inga fler oändliga fax, telefonsamtal, missade tester, information eller möten. Vi hjälper er att kvalitetssäkra, automatisera och spara tid.  Involvera era patienter på ett uppskattat sätt och öka faktorn för lyckade graviditeter. Genom att skapa ett unikt digitalt flöde där patienten alltid vet vad hen ska göra och när hen ska göra det kommer Curoflow IVF vara lösningen er verksamhet och era patienter har väntat på.": "Med Curoflow-IVF digitaliserar vi en mer eller mindre helt analog process. Inga fler oändliga fax, telefonsamtal, missade tester, information eller möten. Vi hjälper er att kvalitetssäkra, automatisera och spara tid.  Involvera era patienter på ett uppskattat sätt och öka faktorn för lyckade graviditeter. Genom att skapa ett unikt digitalt flöde där patienten alltid vet vad hen ska göra och när hen ska göra det kommer Curoflow IVF vara lösningen er verksamhet och era patienter har väntat på.",
        "kommer 2022": "kommer 2022",
        "Curoflow intelligent remittering": "Curoflow intelligent remittering",
        "Remittera patienter med upp till 99% godkännandegrad. Sök bland flera hundra diagnoser och få automatiserade frågor för att fastställa vårdnivå. Om patienten uppfyller fastställda remisskriterier så genererar systemet en remiss per automatik. Möjlighet att välja vårdgivare med rätt kompetens och kortast väntetid presenteras. Uppfylls inte remisskriterierna förser systemet dig med information om lämpliga åtgärder på primärvårdsnivå. Maskininlärning tillämpas vilket gör att eventuella felaktigheter snabbt kan korrigeras.": "Remittera patienter med upp till 99% godkännandegrad. Sök bland flera hundra diagnoser och få automatiserade frågor för att fastställa vårdnivå. Om patienten uppfyller fastställda remisskriterier så genererar systemet en remiss per automatik. Möjlighet att välja vårdgivare med rätt kompetens och kortast väntetid presenteras. Uppfylls inte remisskriterierna förser systemet dig med information om lämpliga åtgärder på primärvårdsnivå. Maskininlärning tillämpas vilket gör att eventuella felaktigheter snabbt kan korrigeras.",
        "Vill du veta mer eller få en gratis demo?": "Vill du veta mer eller få en gratis demo?",
        "Ta kontakt idag.": "Ta kontakt idag.",
        "Namn": "Namn",
        "Titel": "Titel",
        "E-mail": "E-mail",
        "Telefonnummer": "Telefonnummer",
        "Vård. Människor. Empati. Kvalitet. Digitalisering.": "Vård. Människor. Empati. Kvalitet. Digitalisering.",
        "En i grunden ideell tjänst skapad av vårdpersonal och tekniska utvecklare, det var så vår resa började. Idag är vår mission att kraftigt reducera vårdköer, förenkla vårdpersonalens arbete och tillgängliggöra morgondagens digitala verktyg på ett klokt sätt. Framförallt har vi två fokus: Patient och vårdutövare.": "En i grunden ideell tjänst skapad av vårdpersonal och tekniska utvecklare, det var så vår resa började. Idag är vår mission att kraftigt reducera vårdköer, förenkla vårdpersonalens arbete och tillgängliggöra morgondagens digitala verktyg på ett klokt sätt. Framförallt har vi två fokus: Patient och vårdutövare.",
        "Hur kanaliserar vi bäst vår frustration och vilja att förbättra vården? Ja, ungefär så gick tankarna över några koppar kaffe 2017. Det blev startskottet på RemissHjälpen och sedermera Curoflow. Med viljan att kombinera vård, omsorg och teknik började arbetet mot en eftersatt del av vården – remissflödet. Parallellt har tekniken möjliggjort fler lösningar och vi arbetar nu flerdimensionellt för att lösa såväl digital patientkommunikation, automatisering och vårdflöden.": "Hur kanaliserar vi bäst vår frustration och vilja att förbättra vården? Ja, ungefär så gick tankarna över några koppar kaffe 2017. Det blev startskottet på RemissHjälpen och sedermera Curoflow. Med viljan att kombinera vård, omsorg och teknik började arbetet mot en eftersatt del av vården – remissflödet. Parallellt har tekniken möjliggjort fler lösningar och vi arbetar nu flerdimensionellt för att lösa såväl digital patientkommunikation, automatisering och vårdflöden.",
        "Vi drivs av det som är viktigt för vården och av det som är viktigt för patienten. Ett friktionsfritt vårdflöde skapas först när värdet blir lika stort för båda delar vårdkedja . Vi strävar efter att hjälpa er, hjälpa andra.": "Vi drivs av det som är viktigt för vården och av det som är viktigt för patienten. Ett friktionsfritt vårdflöde skapas först när värdet blir lika stort för båda delar vårdkedja . Vi strävar efter att hjälpa er, hjälpa andra.",
        "Hos oss arbetar människor som brinner för att förbättra. Vi drivs av möjligheten att bidra till samhället och lägger stor tonvikt på den mänskliga aspekten. Trots vår relativt korta historia har vi väl utformade ESG-kriterier där vi ständigt strävar efter och mäter hur vi bidrar till en bättre miljö och verkar för alla människors lika värde oavsett ursprung, kön, läggning eller religion.": "Hos oss arbetar människor som brinner för att förbättra. Vi drivs av möjligheten att bidra till samhället och lägger stor tonvikt på den mänskliga aspekten. Trots vår relativt korta historia har vi väl utformade ESG-kriterier där vi ständigt strävar efter och mäter hur vi bidrar till en bättre miljö och verkar för alla människors lika värde oavsett ursprung, kön, läggning eller religion.",
        "Vi vill vara mer än ett vanligt företag. Hos oss skapas mer än teknik. Välkommen till oss så berättar vi mer om hur vi kan hjälpa er verksamhet redan idag!": "Vi vill vara mer än ett vanligt företag. Hos oss skapas mer än teknik. Välkommen till oss så berättar vi mer om hur vi kan hjälpa er verksamhet redan idag!",
        "Människan i fokus": "Människan i fokus",
        "Med utgångspunkt i patientens behov utvecklar vi våra lösningar till att vara ett värdefullt verktyg för vårdgivare och patienter.": "Med utgångspunkt i patientens behov utvecklar vi våra lösningar till att vara ett värdefullt verktyg för vårdgivare och patienter.",
        "Läs vårt produktblad": "Läs vårt produktblad",
        "Kvalitet": "Kvalitet",
        "För en högkvalitativ vård krävs det säkra och pålitliga lösningar – på riktigt!": "För en högkvalitativ vård krävs det säkra och pålitliga lösningar – på riktigt!",
        "Läs vår kvalitetspolicy": "Läs vår kvalitetspolicy",
        "Ansvarsfullt företagande": "Ansvarsfullt företagande",
        "Även om vi arbetar med datorn som arbetsredskap så brinner vi för människor. Läs mer om våra ESG-kriterier.": "Även om vi arbetar med datorn som arbetsredskap så brinner vi för människor. Läs mer om våra ESG-kriterier.",
        "Läs om ESG": "Läs om ESG",
        "Vår ledningsgrupp": "Vår ledningsgrupp",
        "Kontakta mig": "Kontakta mig",
        "Se fler av våra kollegor": "Se fler av våra kollegor",
        "Är du nyfiken på hur vi kan hjälpa er verksamhet att komma igång med digitala vårdmöten och verktyg? Hör gärna av dig så berättar vi mer.": "Är du nyfiken på hur vi kan hjälpa er verksamhet att komma igång med digitala vårdmöten och verktyg? Hör gärna av dig så berättar vi mer.",
        "Vill du alliera dig med aktieägare, företagsledning och medarbetare som brinner för att varje dag tillföra värde och accelerera tillväxt? Då har du hittat rätt!": "Vill du alliera dig med aktieägare, företagsledning och medarbetare som brinner för att varje dag tillföra värde och accelerera tillväxt? Då har du hittat rätt!",
        "Vill du vakna varje morgon och känna att du gör skillnad? Skicka in en spontanansökan eller håll utkik efter lediga jobb. Vi ser fram emot att höra från dig!": "Vill du vakna varje morgon och känna att du gör skillnad? Skicka in en spontanansökan eller håll utkik efter lediga jobb. Vi ser fram emot att höra från dig!",
        "Följ oss på sociala medier": "Följ oss på sociala medier",
        "Sälj & Support": "Sälj & Support",
        "*Glöm inte att du lättast skapar ett supportärende direkt i plattformen": "*Glöm inte att du lättast skapar ett supportärende direkt i plattformen",
        "Jobba med oss": "Jobba med oss",
        "Vill du vara med oss i arbetet att förbättra vården? Besök vår karriärsida och skicka in en intresseanmälan eller sök ditt drömjobb idag!": "Vill du vara med oss i arbetet att förbättra vården? Besök vår karriärsida och skicka in en intresseanmälan eller sök ditt drömjobb idag!",
        "Ansök": "Ansök",
        "Därför behövs en hel sida om GDPR": "Därför behövs en hel sida om GDPR",
        "Att leva upp till GDPR är inte ett val man står inför – det är ett måste. Vi vet hur svårt det kan vara och har därför från start lagt fokus på att utveckla Curoflow i enlighet med GDPR och utan tredjepartsleverantörer utanför EU. Låt oss berätta vad som är viktigt och hur Curoflow troligen är det enda enkla, lagliga alternativet i hantering av persondata och personuppgifter.": "Att leva upp till GDPR är inte ett val man står inför – det är ett måste. Vi vet hur svårt det kan vara och har därför från start lagt fokus på att utveckla Curoflow i enlighet med GDPR och utan tredjepartsleverantörer utanför EU. Låt oss berätta vad som är viktigt och hur Curoflow troligen är det enda enkla, lagliga alternativet i hantering av persondata och personuppgifter.",
        "Vi tar den korta versionen...": "Vi tar den korta versionen...",
        "Den 16 juli 2020 meddelade EU-domstolen Schrems II-domen med betydande konsekvenser för användningen av amerikanska och utomeuropeiska molntjänster. Kunder till utomeuropeiska molntjänstleverantörer måste nu själva verifiera dataskyddslagarna i mottagarlandet, dokumentera sin riskbedömning och kommunicera med sina kunder.": "Den 16 juli 2020 meddelade EU-domstolen Schrems II-domen med betydande konsekvenser för användningen av amerikanska och utomeuropeiska molntjänster. Kunder till utomeuropeiska molntjänstleverantörer måste nu själva verifiera dataskyddslagarna i mottagarlandet, dokumentera sin riskbedömning och kommunicera med sina kunder.",
        "Den 16 juli 2020 ogiltigförklarade Europeiska unionens domstol (EG-domstolen) i sitt ärende C-311/18 dataskyddskommissionär mot Facebook Ireland och Maximillian Schrems (kallat “Schrems II-målet”) EU – USA Privacy Shield. Domstolen ifrågasatte i vilken utsträckning överföringar kan rättfärdigas genom Europeiska Kommissionens standardavtalsklausuler (SCC) för överföringar av persondata till USA och globalt. SCC: s var fortfarande giltiga som en överföringsmekanism i princip men skulle kräva ytterligare mycket arbete.": "Den 16 juli 2020 ogiltigförklarade Europeiska unionens domstol (EG-domstolen) i sitt ärende C-311/18 dataskyddskommissionär mot Facebook Ireland och Maximillian Schrems (kallat “Schrems II-målet”) EU – USA Privacy Shield. Domstolen ifrågasatte i vilken utsträckning överföringar kan rättfärdigas genom Europeiska Kommissionens standardavtalsklausuler (SCC) för överföringar av persondata till USA och globalt. SCC: s var fortfarande giltiga som en överföringsmekanism i princip men skulle kräva ytterligare mycket arbete.",
        "Vad behöver ni tänka på för era patienters skull?": "Vad behöver ni tänka på för era patienters skull?",
        "Om ni väljer en leverantör som använder tredjepartslösningar utanför EU/EES måste de och framförallt ni som personuppgiftsansvariga tillse att GDPR efterlevs. Sedan sommaren 2020 är EU-US Privacy Shield inte längre tillämplig vilket innebär att EU:s standardklausuler måste tillämpas vid överföring eller risk för överföring till land utanför EU/EES. För att på ett korrekt sätt efterleva klausulerna krävs enskilt och uttryckligt samtycke från varje användare/patient som använder den tjänst ni har köpt in. Det räcker alltså inte med godkännande av en integritetspolicy.": "Om ni väljer en leverantör som använder tredjepartslösningar utanför EU/EES måste de och framförallt ni som personuppgiftsansvariga tillse att GDPR efterlevs. Sedan sommaren 2020 är EU-US Privacy Shield inte längre tillämplig vilket innebär att EU:s standardklausuler måste tillämpas vid överföring eller risk för överföring till land utanför EU/EES. För att på ett korrekt sätt efterleva klausulerna krävs enskilt och uttryckligt samtycke från varje användare/patient som använder den tjänst ni har köpt in. Det räcker alltså inte med godkännande av en integritetspolicy.",
        "Utöver det krävs en omfattande utredning om det land där persondata kan komma att hanteras (ex USA). En sådan utredning är så när som på omöjlig att utföra utan en enorm tidsinsats, i både kronor och tid räknat.": "Utöver det krävs en omfattande utredning om det land där persondata kan komma att hanteras (ex USA). En sådan utredning är så när som på omöjlig att utföra utan en enorm tidsinsats, i både kronor och tid räknat.",
        "Ni, ihop med er leverantör behöver tillse att persondata hanteras i enlighet med GDPR. Används tredjepartslösningar utanför EU/EES kan ni så när som på räkna med att nödvändiga åtgärder inte är vidtagna av leverantören och att det ansvaret då faller på er som personuppgiftsansvariga.": "Ni, ihop med er leverantör behöver tillse att persondata hanteras i enlighet med GDPR. Används tredjepartslösningar utanför EU/EES kan ni så när som på räkna med att nödvändiga åtgärder inte är vidtagna av leverantören och att det ansvaret då faller på er som personuppgiftsansvariga.",
        "Vad är lösningen och hur gör ni rätt?": "Vad är lösningen och hur gör ni rätt?",
        "Det enklaste sättet, oavsett vad man än väljer att göra, är att göra rätt från början. Vi på Curoflow har redan från start utvecklat all teknologi på egen hand. Det innebär att vi till skillnad från de flesta andra alternativ på marknaden kan erbjuda en helt egenutvecklad molntjänst som inte är beroende av tredjepartslösningar utanför EU/EES.": "Det enklaste sättet, oavsett vad man än väljer att göra, är att göra rätt från början. Vi på Curoflow har redan från start utvecklat all teknologi på egen hand. Det innebär att vi till skillnad från de flesta andra alternativ på marknaden kan erbjuda en helt egenutvecklad molntjänst som inte är beroende av tredjepartslösningar utanför EU/EES.",
        "Exempelvis är vår videochatt egenutvecklad från grunden vilket gör att vi kan lagra och hanteras all persondata i serverhallar i Falkenberg, Sverige. När vi har valt underleverantör för kortbetalning online har vi valt ett svenskt företag och samtidigt valt bort leverantörer som inte kan garantera att persondata hanteras inom EU.": "Exempelvis är vår videochatt egenutvecklad från grunden vilket gör att vi kan lagra och hanteras all persondata i serverhallar i Falkenberg, Sverige. När vi har valt underleverantör för kortbetalning online har vi valt ett svenskt företag och samtidigt valt bort leverantörer som inte kan garantera att persondata hanteras inom EU.",
        "Genom att välja Curoflows plattform väljer ni inte bara en molntjänst och vårdplattform som kommer att effektivisera och förbättra delar av er verksamhet – ni väljer att ta ansvar för era patienter och deras persondata.": "Genom att välja Curoflows plattform väljer ni inte bara en molntjänst och vårdplattform som kommer att effektivisera och förbättra delar av er verksamhet – ni väljer att ta ansvar för era patienter och deras persondata.",
        "Vi förstår att GDPR är krångligt och svårt, men år 2021 har vi inte råd att inte följa lagen och hantera våra patienters persondata på ett korrekt sätt.": "Vi förstår att GDPR är krångligt och svårt, men år 2021 har vi inte råd att inte följa lagen och hantera våra patienters persondata på ett korrekt sätt.",
        "Kontakta oss för en längre version": "Kontakta oss för en längre version",
        "Vi svarar på era frågor och hjälper er att komma igång": "Vi svarar på era frågor och hjälper er att komma igång",
        "Ta kontakt med oss så berättar vi mer om GDPR och visar hur Curoflow kan fungera i just er verksamhet.": "Ta kontakt med oss så berättar vi mer om GDPR och visar hur Curoflow kan fungera i just er verksamhet.",
        "Våra ledord": "Våra ledord",
        "Ta kontakt": "Ta kontakt",
        "Satsa på framtidens vård": "Satsa på framtidens vård",
        "Tror du likt oss att framtiden ligger i kombinationen digital och fysisk vård? Vill du alliera dig med aktieägare, företagsledning och medarbetare som brinner för att varje dag tillföra värde och accelerera tillväxt? Då har du hittat rätt!": "Tror du likt oss att framtiden ligger i kombinationen digital och fysisk vård? Vill du alliera dig med aktieägare, företagsledning och medarbetare som brinner för att varje dag tillföra värde och accelerera tillväxt? Då har du hittat rätt!",
        "Några av våra partners": "Några av våra partners",
        "Några av våra samarbeten": "Några av våra samarbeten",



        // Privacy policy
        "Curoflow värdesätter din personliga integritet. I denna integritetspolicy informerar vi dig om hur vi hanterar de personuppgifter vi samlar in eller erhåller via vår webbplats och på andra sätt. Curoflow Technology AB, org. nr. 559123-3654, (”Curoflow”) är personuppgiftsansvarig.": "Curoflow värdesätter din personliga integritet. I denna integritetspolicy informerar vi dig om hur vi hanterar de personuppgifter vi samlar in eller erhåller via vår webbplats och på andra sätt. Curoflow Technology AB, org. nr. 559123-3654, (”Curoflow”) är personuppgiftsansvarig.",
        "Vi behandlar alltid dina personuppgifter i enlighet med gällande dataskyddslagstiftning, inklusive den allmänna dataskyddsförordningen 2016/679 ('GDPR'). För information om vilka cookies vi använder på vår webbplats, se vår": "Vi behandlar alltid dina personuppgifter i enlighet med gällande dataskyddslagstiftning, inklusive den allmänna dataskyddsförordningen 2016/679 ('GDPR'). För information om vilka cookies vi använder på vår webbplats, se vår",
        "Vems och vilka personuppgifter behandlar vi?": "Vems och vilka personuppgifter behandlar vi?",
        "Vi behandlar dina personuppgifter i följande fall:": "Vi behandlar dina personuppgifter i följande fall:",
        "När du är kontaktperson hos kund eller annan intressent i samband med att vi utför uppdrag inom vår konsultverksamhet, eller som vi behandlar när uppdraget förbereds, utförs eller administreras. Detta inkluderar även kontaktpersoner hos potentiella kunder, andra rådgivare eller personer som vi önskar att kommunicera med för att informera om våra tjänster.": "När du är kontaktperson hos kund eller annan intressent i samband med att vi utför uppdrag inom vår konsultverksamhet, eller som vi behandlar när uppdraget förbereds, utförs eller administreras. Detta inkluderar även kontaktpersoner hos potentiella kunder, andra rådgivare eller personer som vi önskar att kommunicera med för att informera om våra tjänster.",
        "När du är kontaktperson för en till oss potentiell kund eller i annat ärende kontaktar oss via kontaktformulär på vår webbplats, eller direkt till de mejladresser Curoflow har angivet som sina. Detta inkluderar även andra rådgivare eller personer som vi önskar att kommunicera med för att informera om våra tjänster.": "När du är kontaktperson för en till oss potentiell kund eller i annat ärende kontaktar oss via kontaktformulär på vår webbplats, eller direkt till de mejladresser Curoflow har angivet som sina. Detta inkluderar även andra rådgivare eller personer som vi önskar att kommunicera med för att informera om våra tjänster.",
        "När du är leverantör, samarbetspartner eller annan part som utför, eller kan komma att utföra, tjänster åt oss.": "När du är leverantör, samarbetspartner eller annan part som utför, eller kan komma att utföra, tjänster åt oss.",
        "När du anmäler dig och/eller deltar i något av våra evenemang, seminarium eller liknande.": "När du anmäler dig och/eller deltar i något av våra evenemang, seminarium eller liknande.",
        "När du besöker vår webbplats och, i förekommande fall, kontaktar oss via vårt kontaktformulär eller anmäler dig till vårt nyhetsbrev.": "När du besöker vår webbplats och, i förekommande fall, kontaktar oss via vårt kontaktformulär eller anmäler dig till vårt nyhetsbrev.",
        "Personuppgifterna omfattar vanligtvis namn, kontaktuppgifter och titel, men i förekommande fall även bankuppgifter. Vi behandlar dessa personuppgifter för att utföra och administrera kontakten med dig, för att tillvarata dina eller våra intressen, hantera leverantörsförhållanden samt för redovisnings- och faktureringsändamål. Därutöver kan uppgifterna utgöra underlag för intern marknads- och klientanalys och statistik, riskhantering och för metod- och affärsutveckling. Personuppgifterna kan även komma att användas för marknadsföringsåtgärder.": "Personuppgifterna omfattar vanligtvis namn, kontaktuppgifter och titel, men i förekommande fall även bankuppgifter. Vi behandlar dessa personuppgifter för att utföra och administrera kontakten med dig, för att tillvarata dina eller våra intressen, hantera leverantörsförhållanden samt för redovisnings- och faktureringsändamål. Därutöver kan uppgifterna utgöra underlag för intern marknads- och klientanalys och statistik, riskhantering och för metod- och affärsutveckling. Personuppgifterna kan även komma att användas för marknadsföringsåtgärder.",
        "I samband med evenemang och seminarium kan vi komma att behandla uppgifter om allergier och matpreferenser samt, i vissa fall, bilder och inspelat material på plats. Vi behandlar personuppgifterna för att kunna anordna evenemang och seminarier, besvara din förfrågan om kontakt och/eller för marknadsföringsändamål. På vår webbplats använder vi även cookies för att optimera Webbplatsbesökares upplevelse, se vår": "I samband med evenemang och seminarium kan vi komma att behandla uppgifter om allergier och matpreferenser samt, i vissa fall, bilder och inspelat material på plats. Vi behandlar personuppgifterna för att kunna anordna evenemang och seminarier, besvara din förfrågan om kontakt och/eller för marknadsföringsändamål. På vår webbplats använder vi även cookies för att optimera Webbplatsbesökares upplevelse, se vår",
        "Cookie Policy": "Cookie Policy",
        "för mer information.": "för mer information.",
        "Personuppgifterna härrör från dig själv eller din arbetsgivare, medan andra uppgifter kan uppkomma inom ramen för utförande av våra tjänster. I förekommande fall samlar vi in och lagrar personuppgifter från externa källor som offentliga bolags- och adressregister, sökbara och offentliga webbplatser och/eller från kreditupplysningsföretag.": "Personuppgifterna härrör från dig själv eller din arbetsgivare, medan andra uppgifter kan uppkomma inom ramen för utförande av våra tjänster. I förekommande fall samlar vi in och lagrar personuppgifter från externa källor som offentliga bolags- och adressregister, sökbara och offentliga webbplatser och/eller från kreditupplysningsföretag.",
        "Rättslig grund för behandlingen": "Rättslig grund för behandlingen",
        "Behandlingen av personuppgifter sker när det är nödvändigt för oss för att vi ska kunna fullgöra vårt avtal med dig eller för att kunna vidta åtgärder innan avtal ingås.": "Behandlingen av personuppgifter sker när det är nödvändigt för oss för att vi ska kunna fullgöra vårt avtal med dig eller för att kunna vidta åtgärder innan avtal ingås.",
        "Deltagares och Webbplatsbesökares personuppgifter behandlas för att uppfylla ett berättigat intresse, så som att bjuda in till evenemang eller utskick av nyhetsbrev, där vi bedömt att det berättigade intresset väger tyngre än den registrerades intressen eller grundläggande rättigheter och friheter (intresseavvägning).": "Deltagares och Webbplatsbesökares personuppgifter behandlas för att uppfylla ett berättigat intresse, så som att bjuda in till evenemang eller utskick av nyhetsbrev, där vi bedömt att det berättigade intresset väger tyngre än den registrerades intressen eller grundläggande rättigheter och friheter (intresseavvägning).",
        "Lagring och utlämning av personuppgifter": "Lagring och utlämning av personuppgifter",
        "Vi sparar personuppgifter under den period det är nödvändigt för det aktuella ändamålet för vilket vi behandlar uppgifterna. Om du avanmäler dig för nyhetsbrev kommer uppgifterna, till den del de sparats för sådana ändamål, att raderas. Efter deltagande i evenemang eller seminarium behåller vi de personuppgifter som behandlades för de angivna ändamålen personuppgifter i ett (1) år innan vi raderar dem. Vi vill dock uppmärksamma dig på vid radering av dina personuppgifter för viss behandling kan vi komma att behålla personuppgifterna för andra ändamål, t.ex. om du fortfarande är kund eller leverantör.": "Vi sparar personuppgifter under den period det är nödvändigt för det aktuella ändamålet för vilket vi behandlar uppgifterna. Om du avanmäler dig för nyhetsbrev kommer uppgifterna, till den del de sparats för sådana ändamål, att raderas. Efter deltagande i evenemang eller seminarium behåller vi de personuppgifter som behandlades för de angivna ändamålen personuppgifter i ett (1) år innan vi raderar dem. Vi vill dock uppmärksamma dig på vid radering av dina personuppgifter för viss behandling kan vi komma att behålla personuppgifterna för andra ändamål, t.ex. om du fortfarande är kund eller leverantör.",
        "Överföring till land utanför EU/EES": "Överföring till land utanför EU/EES",
        "Curoflow kan anlita externa samarbetspartners och leverantörer att utföra uppgifter för vår räkning, t ex för att tillhandahålla IT-tjänster, analyser eller statistik. Utförandet av dessa tjänster kan innebära att våra samarbetspartners, både inom och utanför EU/EES, får tillgång till era personuppgifter.": "Curoflow kan anlita externa samarbetspartners och leverantörer att utföra uppgifter för vår räkning, t ex för att tillhandahålla IT-tjänster, analyser eller statistik. Utförandet av dessa tjänster kan innebära att våra samarbetspartners, både inom och utanför EU/EES, får tillgång till era personuppgifter.",
        "Samarbetspartners som hanterar personuppgifter för Curoflow:s räkning undertecknar alltid avtal med oss för att säkerställa en hög skyddsnivå för era personuppgifter. I förhållande till samarbetspartners utanför EU/EES vidtas i nödvändiga fall dessutom särskilda skyddsåtgärder, t.ex. genom att vi ingår avtal som inkluderar standardavtalsklausuler för dataöverföring i enlighet med EU-kommissionens beslut och som finns tillgängliga på EU-kommissionens hemsida.": "Samarbetspartners som hanterar personuppgifter för Curoflow:s räkning undertecknar alltid avtal med oss för att säkerställa en hög skyddsnivå för era personuppgifter. I förhållande till samarbetspartners utanför EU/EES vidtas i nödvändiga fall dessutom särskilda skyddsåtgärder, t.ex. genom att vi ingår avtal som inkluderar standardavtalsklausuler för dataöverföring i enlighet med EU-kommissionens beslut och som finns tillgängliga på EU-kommissionens hemsida.",
        "Denna integritetspolicy avser att beskriva det sätt vi hanterar personuppgifter från våra befintliga eller potentiella kunder. De personuppgifter (från vårdpersonal, administratörer och patienter) som hanteras i Curoflows medicintekniska plattform hanteras inom EU/EES och utan risk för överföring utanför EU/EES.": "Denna integritetspolicy avser att beskriva det sätt vi hanterar personuppgifter från våra befintliga eller potentiella kunder. De personuppgifter (från vårdpersonal, administratörer och patienter) som hanteras i Curoflows medicintekniska plattform hanteras inom EU/EES och utan risk för överföring utanför EU/EES.",
        "Dina rättigheter": "Dina rättigheter",
        "Nedan följer en lista över de rättigheter du har avseende vår behandling av dina personuppgifter. Rättigheterna är inte absoluta och en begäran om utövande av rättigheterna resulterar därför inte alltid i någon åtgärd.": "Nedan följer en lista över de rättigheter du har avseende vår behandling av dina personuppgifter. Rättigheterna är inte absoluta och en begäran om utövande av rättigheterna resulterar därför inte alltid i någon åtgärd.",
        "Dina rättigheter i GDPR inkluderar:": "Dina rättigheter i GDPR inkluderar:",
        "Rätt till tillgång – Enligt artikel 15 GDPR har du rätt att få tillgång till dina personuppgifter samt viss information rörande behandlingen av dem. Den informationen framgår av detta dokument.": "Rätt till tillgång – Enligt artikel 15 GDPR har du rätt att få tillgång till dina personuppgifter samt viss information rörande behandlingen av dem. Den informationen framgår av detta dokument.",
        "Rätt till rättelse – Enligt artikel 16 GDPR har du rätt att få felaktiga personuppgifter rättade samt att komplettera ofullständiga personuppgifter.": "Rätt till rättelse – Enligt artikel 16 GDPR har du rätt att få felaktiga personuppgifter rättade samt att komplettera ofullständiga personuppgifter.",
        "Rätt till radering – Under vissa omständigheter har du enligt artikel 17 GDPR rätt att få personuppgifterna raderade. Detta är den så kallade 'rätten att bli bortglömd'.": "Rätt till radering – Under vissa omständigheter har du enligt artikel 17 GDPR rätt att få personuppgifterna raderade. Detta är den så kallade 'rätten att bli bortglömd'.",
        "Rätt till begränsning av behandling – Under vissa omständigheter har du enligt artikel 18 GDPR rätt att begränsa behandlingen som vi vidtar.": "Rätt till begränsning av behandling – Under vissa omständigheter har du enligt artikel 18 GDPR rätt att begränsa behandlingen som vi vidtar.",
        "Rätt till dataportabilitet – Enligt artikel 20 GDPR har du rätt att få ut personuppgifterna (eller få dem överförda till en annan personuppgiftsansvarig) i ett strukturerat, allmänt använt och maskinläsbart format.": "Rätt till dataportabilitet – Enligt artikel 20 GDPR har du rätt att få ut personuppgifterna (eller få dem överförda till en annan personuppgiftsansvarig) i ett strukturerat, allmänt använt och maskinläsbart format.",
        "Rätt att göra invändningar – Enligt artikel 21 GDPR har du rätt att invända mot särskilda personuppgiftsbehandlingar.": "Rätt att göra invändningar – Enligt artikel 21 GDPR har du rätt att invända mot särskilda personuppgiftsbehandlingar.",
        "Kontaktinformation": "Kontaktinformation",
        "Kontakta oss på": "Kontakta oss på",
        "om du har några frågor angående vår personuppgiftsbehandling eller önskar utöva dina rättigheter enligt ovan. Om du är missnöjd med vår behandling finns möjlighet att framföra klagomål hos Integritetsskyddsmyndigheten via www.imy.se. Du kan även vända dig till tillsynsmyndigheten i det land där du bor eller arbetar.": "om du har några frågor angående vår personuppgiftsbehandling eller önskar utöva dina rättigheter enligt ovan. Om du är missnöjd med vår behandling finns möjlighet att framföra klagomål hos Integritetsskyddsmyndigheten via www.imy.se. Du kan även vända dig till tillsynsmyndigheten i det land där du bor eller arbetar.",
        "Förtydligande:": "Förtydligande:",

        // Cookies
        "Vad är cookies?": "Vad är cookies?",
        "Curoflow Technology AB, org. nr. 559123-3654, ('Curoflow') använder cookies för att anpassa vår webbplats och optimera din upplevelse. Cookies är små textfiler som lagras på din enhet när du besöker webbplatser.": "Curoflow Technology AB, org. nr. 559123-3654, ('Curoflow') använder cookies för att anpassa vår webbplats och optimera din upplevelse. Cookies är små textfiler som lagras på din enhet när du besöker webbplatser.",
        "Det finns olika typer av cookies. Sessionscookies lagras temporärt tills du har stängt ned din webbläsare medan permanenta cookies sparas under en längre tid. Cookies kan vidare delas upp i förstapartscookies, som vi sätter själva på vår webbplats, och tredjepartscookies, som tillhör en annan domän. På vår webbplats använder vi oss av permanenta första- och tredjepartscookies. Vi behandlar inga personuppgifter i våra cookies.": "Det finns olika typer av cookies. Sessionscookies lagras temporärt tills du har stängt ned din webbläsare medan permanenta cookies sparas under en längre tid. Cookies kan vidare delas upp i förstapartscookies, som vi sätter själva på vår webbplats, och tredjepartscookies, som tillhör en annan domän. På vår webbplats använder vi oss av permanenta första- och tredjepartscookies. Vi behandlar inga personuppgifter i våra cookies.",
        "Vi har delat upp våra cookies i kategorierna 'nödvändiga cookies' och 'statistikcookies'. Nödvändiga cookies används för att vår webbplats ska fungera på ett säkert sätt. Statistikcookies tillhandahåller analys av data och webbplatstrafik som gör att vi kan anpassa användarupplevelsen på vår webbplats.": "Vi har delat upp våra cookies i kategorierna 'nödvändiga cookies' och 'statistikcookies'. Nödvändiga cookies används för att vår webbplats ska fungera på ett säkert sätt. Statistikcookies tillhandahåller analys av data och webbplatstrafik som gör att vi kan anpassa användarupplevelsen på vår webbplats.",
        "Vilka cookies använder vi?": "Vilka cookies använder vi?",
        "För att vara transparenta har vi sammanfattat information om de cookies vi använder på vår hemsida nedan.": "För att vara transparenta har vi sammanfattat information om de cookies vi använder på vår hemsida nedan.",
        "Nödvändiga cookies": "Nödvändiga cookies",
        "Namn på cookie: cookielawinfo-checkbox-necessary": "Namn på cookie: cookielawinfo-checkbox-necessary",
        "Typ av cookie: Permanent förstapartscookie": "Typ av cookie: Permanent förstapartscookie",
        "Ändamål: Hantering av vår cookiebanner, spara besökares godkännande av nödvändiga cookies": "Ändamål: Hantering av vår cookiebanner, spara besökares godkännande av nödvändiga cookies",
        "Lagringstid: 11 månader": "Lagringstid: 11 månader",
        "Datan delas inte": "Datan delas inte",
        "Namn på cookie: cookielawinfo-checkbox-non-necessary": "Namn på cookie: cookielawinfo-checkbox-non-necessary",
        "Typ av cookie: Permanent förstapartscookie": "Typ av cookie: Permanent förstapartscookie",
        "Ändamål: Hantering av vår cookiebanner, spara besökares godkännande av statistikcookies.": "Ändamål: Hantering av vår cookiebanner, spara besökares godkännande av statistikcookies.",
        "Namn på cookie: viewed_cookie_policy": "Namn på cookie: viewed_cookie_policy",
        "Typ av cookie: Permanent förstapartscookie": "Typ av cookie: Permanent förstapartscookie",
        "Ändamål: Hantering av vår cookiebanner, spara huruvida besökaren har godkänt användningen av cookies.": "Ändamål: Hantering av vår cookiebanner, spara huruvida besökaren har godkänt användningen av cookies.",
        "Statistikcookies": "Statistikcookies",
        "Namn på cookie: _ga": "Namn på cookie: _ga",
        "Leverantör: Google": "Leverantör: Google",
        "Typ av cookie: Permanent tredjepartscookie": "Typ av cookie: Permanent tredjepartscookie",
        "Ändamål: Identifiering av unika besökare och beteende på webbplatsen": "Ändamål: Identifiering av unika besökare och beteende på webbplatsen",
        "Lagringstid: 2 år": "Lagringstid: 2 år",
        "Data delas till Google LLC": "Data delas till Google LLC",
        "Namn på cookie: _gat": "Namn på cookie: _gat",
        "Ändamål: Implementering av anropsbegränsning vid hög webbplatstrafik": "Ändamål: Implementering av anropsbegränsning vid hög webbplatstrafik",
        "Lagringstid: 1 minut": "Lagringstid: 1 minut",
        "Namn på cookie: _gid": "Namn på cookie: _gid",
        "Ändamål: Identifiering av unika besökare Lagringstid: 24 timmar": "Ändamål: Identifiering av unika besökare Lagringstid: 24 timmar",
        "Lagringstid: 24 timmar": "Lagringstid: 24 timmar",
        "Personuppgifter": "Personuppgifter",
        "Du har möjlighet att ändra dina webbläsarinställningar så att du uppmärksammas om en cookie skickas till din enhet eller för att ange som huvudregel att cookies blockeras. Du kan också välja att radera alla de cookies som finns lagrade på din dator. Curoflow:s användning av nödvändiga cookies är enligt ovan i vissa fall väsentliga för att tillhandahålla viktiga funktioner på vår webbplats. Om du väljer att blockera våra cookies kan det innebära viss begränsad funktionalitet på webbplatsen. Vad avser de cookies som levereras av Google finns särskilda tillägg att hämta": "Du har möjlighet att ändra dina webbläsarinställningar så att du uppmärksammas om en cookie skickas till din enhet eller för att ange som huvudregel att cookies blockeras. Du kan också välja att radera alla de cookies som finns lagrade på din dator. Curoflow:s användning av nödvändiga cookies är enligt ovan i vissa fall väsentliga för att tillhandahålla viktiga funktioner på vår webbplats. Om du väljer att blockera våra cookies kan det innebära viss begränsad funktionalitet på webbplatsen. Vad avser de cookies som levereras av Google finns särskilda tillägg att hämta",
        "här": "här",
        "Du kan ändra ditt godkännande av statistikcookies i vår cookiebanner. Ändrade cookieinställningar börjar gälla från och med tillfället för ändringen och påverkar inte tidigare insamlad information via cookies.": "Du kan ändra ditt godkännande av statistikcookies i vår cookiebanner. Ändrade cookieinställningar börjar gälla från och med tillfället för ändringen och påverkar inte tidigare insamlad information via cookies.",
        "För mer information om cookies, se": "För mer information om cookies, se",
        "För information om hur vi hanterar dina personuppgifter, se vår": "För information om hur vi hanterar dina personuppgifter, se vår",
        "Är du nyfiken på hur vi kan hjälpa er verksamhet att komma igång med digitala vårdmöten och verktyg? Hör gärna av dig så berättar vi mer. Du når oss per telefon eller mail.": "Är du nyfiken på hur vi kan hjälpa er verksamhet att komma igång med digitala vårdmöten och verktyg? Hör gärna av dig så berättar vi mer. Du når oss per telefon eller mail.",
        "Är du redan kund och behöver support kan du nå oss per telefon helgfri måndag-fredag 08.30-17.00. Vi finns även tillgängliga per mail under kvällar och helger.": "Är du redan kund och behöver support kan du nå oss per telefon helgfri måndag-fredag 08.30-17.00. Vi finns även tillgängliga per mail under kvällar och helger.",
        "för mer information.": "för mer information.",




        /*
        "Välkommen till ert nya jag": "Välkommen till ert nya jag",
        "Att göra er verksamhet digital eller digifysisk behöver inte vara svårt, tidskrävande eller dyrt. Vi kom från den fysiska vården, vi blev digitala och vi har nu utvecklat möjligheten för fler att ta klivet. Vi hjälper er hela vägen, från start till för alltid.": "Att göra er verksamhet digital eller digifysisk behöver inte vara svårt, tidskrävande eller dyrt. Vi kom från den fysiska vården, vi blev digitala och vi har nu utvecklat möjligheten för fler att ta klivet. Vi hjälper er hela vägen, från start till för alltid.",
        "Kontakta oss för en introduktion": "Kontakta oss för en introduktion",
        "Varför välja Curoflow?": "Varför välja Curoflow?",
        "Vi kan": "Vi kan",
        "Funktioner": "Funktioner",
        "för vården": "för vården",
        "Lätt att": "Lätt att",""
        "komma igång": "komma igång",
        "Vi lagrar och hanterar alla personuppgifter och all persondata i Sverige och EU. Till skillnad från majoriteten av leverantörer som är beroende av tredjepartslösningar utanför EU/EES.": "Vi lagrar och hanterar alla personuppgifter och all persondata i Sverige och EU. Till skillnad från majoriteten av leverantörer som är beroende av tredjepartslösningar utanför EU/EES.",
        "Hur väl uppfyller ni lagen och hur måna är ni om era patienters uppgifter?": "Hur väl uppfyller ni lagen och hur måna är ni om era patienters uppgifter?",
        "Curoflow erbjuder möjlighet till en mängd individuella anpassningar. Allt för att passa er verksamhet och de flöden ni behöver. Tonvikt har lagts på enkelhet och automatisering för att förenkla för både er och era patienter.": "Curoflow erbjuder möjlighet till en mängd individuella anpassningar. Allt för att passa er verksamhet och de flöden ni behöver. Tonvikt har lagts på enkelhet och automatisering för att förenkla för både er och era patienter.",
        "Vi vill att digitalisering ska vara enkelt och utan barriärer. Vi hjälper er att komma igång dagen efter, eller med mer djupgående utbildningar där vi ser till att alla känner sig trygga i funktion och design.": "Vi vill att digitalisering ska vara enkelt och utan barriärer. Vi hjälper er att komma igång dagen efter, eller med mer djupgående utbildningar där vi ser till att alla känner sig trygga i funktion och design.",
        "Vi hjälper er att komma igång": "Vi hjälper er att komma igång",
        "Vill ni veta varför Curoflow troligen är den säkraste vårdplattformen ur ett GDPR-perspektiv? Undrar ni över hur Curoflow ser ut och hur det hade fungerat i er verksamhet? Är ni redo att byta till en leverantör som kommer från vården och förstår era behov? Oavsett vad finns vi här för att svara på era frågor.": "Vill ni veta varför Curoflow troligen är den säkraste vårdplattformen ur ett GDPR-perspektiv? Undrar ni över hur Curoflow ser ut och hur det hade fungerat i er verksamhet? Är ni redo att byta till en leverantör som kommer från vården och förstår era behov? Oavsett vad finns vi här för att svara på era frågor.",
        "Satsa på framtidens vård": "Satsa på framtidens vård",
        "Tror du likt oss att framtiden ligger i kombinationen digital och fysisk vård? Vill du alliera dig med aktieägare, företagsledning och medarbetare som brinner för att varje dag tillföra värde och accelerera tillväxt? Då har du hittat rätt!.": "Tror du likt oss att framtiden ligger i kombinationen digital och fysisk vård? Vill du alliera dig med aktieägare, företagsledning och medarbetare som brinner för att varje dag tillföra värde och accelerera tillväxt? Då har du hittat rätt!.",
        "Är du nyfiken på hur vi kan hjälpa er verksamhet att komma igång med digitala vårdmöten och verktyg? Hör gärna av dig så berättar vi mer. Du når oss per telefon eller mail.": "Är du nyfiken på hur vi kan hjälpa er verksamhet att komma igång med digitala vårdmöten och verktyg? Hör gärna av dig så berättar vi mer. Du når oss per telefon eller mail.",
        "Är du redan kund och behöver support kan du nå oss per telefon helgfri måndag-fredag 08.30-17.00. Vi finns även tillgängliga per mail under kvällar och helger.": "Är du redan kund och behöver support kan du nå oss per telefon helgfri måndag-fredag 08.30-17.00. Vi finns även tillgängliga per mail under kvällar och helger."
        */

        "Vi använder cookies": "Vi använder cookies",
        "För att optimera ditt användande av vår webbplats använder Curoflow nödvändiga cookies. Om du samtycker använder vi även statistik- & marknadsföringscookies i syfte att analysera webbplatstrafik och erbjuda mer relevant kommunikation. Datan delas med cookieleverantören och du kan återkalla ditt samtycke när som helst.": "För att optimera ditt användande av vår webbplats använder Curoflow nödvändiga cookies. Om du samtycker använder vi även statistik- & marknadsföringscookies i syfte att analysera webbplatstrafik och erbjuda mer relevant kommunikation. Datan delas med cookieleverantören och du kan återkalla ditt samtycke när som helst."

    },
    "en": {
        'Save': 'Save',
        'off / on': 'off / on',
        'To optimize your use of our website, Curoflow uses necessary cookies. If you agree, we also use statistics & marketing cookies in order to analyze website traffic and offer more relevant communication. The data is shared with the cookie provider and you can revoke your consent at any time.': 'To optimize your use of our website, Curoflow uses necessary cookies. If you agree, we also use statistics & marketing cookies in order to analyze website traffic and offer more relevant communication. The data is shared with the cookie provider and you can revoke your consent at any time.',
        'Necessary cookies': 'Necessary cookies',
        'Statistics Cookies2': 'Statistics cookies',
        'Marketing cookies': 'Marketing cookies',
        'Change settings': 'Change settings',
        'Accept all': 'Accept all',
        'Upload a valid file format (JPG, PNG, or WebP) with a maximum size of 10MB': 'Upload a valid file format (JPG, PNG, or WebP) with a maximum size of 10MB',
        'Button 1': 'Button 1',
        'Button 2': 'Button 2',
        'Button 3': 'Button 3',
        'Button 4': 'Button 4',
        'Create your app': 'Create your app',
        'Back': 'Back',
        'How many buttons do you need?': 'How many buttons do you need?',
        "Step 3. Buttons": "Step 3. Buttons",
        "Hex code secondary color": "Hex code secondary color",
        "Hex code primary color": "Hex code primary color",
        'Step 2. Colors': 'Step 2. Colors',
        'Attach image': 'Attach image',
        'Text on button 1': 'Text on button 1',
        'Text on button 2': 'Text on button 2',
        'Text on button 3': 'Text on button 3',
        'Text on button 4': 'Text on button 4',
        'Attach Logo': 'Attach Logo',
        'Image': 'Image',
        'Logotype': 'Logotype',
        "Step 1. Attachments": "Step 1. Attachments",
        "Insights": "Insights",
        'NewCompany': 'Company',
        'newTitle': 'Title',
        "GDPR compliance": "GDPR compliance",
        "Our platform": "Our platform",
        "Privacy policy": "Privacy policy",
        'More news': 'More news',
        'More articles': 'More articles',
        'Please fill in your work email': 'Please fill in your work email',
        'Please use at least 25 letters': 'Please use at least 25 letters',
        'Name': 'Name',
        'Job title': 'Job title',
        'Work email': 'Work email',
        'Telefonnumer': 'Phone',
        'Land': 'Country',
        'Read more success stories': 'Read more success stories',
        'Articles': 'Articles',
        "Company": "Company",
        "Resources": "Resources",
        'Telemedicine platform': 'Telemedicine platform',
        "Subject line": "Subject line",
        'Please fill out the form to send us an email': 'Please fill out the form to us an email',
        'Contact home page': 'Contact',
        'Learn more': 'Learn more',
        'Here you find articles, white papers, guides etc. about telemedicine and the Curoflow telehealth platform.': 'Here you find articles, white papers, guides etc. about telemedicine and the Curoflow telehealth platform.',
        'Fill in the required information and try again': 'Fill in the required information and try again',
        "Next": "Next",
        'Previous': 'Previous',
        'Read more': 'Read more',
        'Book a demo': 'Book a demo',
        "See more of our coworkers": "See more of our coworkers",
        "Book a demo of Curoflow": "Book a demo of Curoflow",
        "Contact our support": "Contact our support",
        "Download product sheet": "Download product sheet",
        "Podcast": "Podcast",
        "Career": "Career",
        'thank-you': 'en/thank-you',
        "To the homepage": "To the homepage",
        "For urgent matters, call +46 (0)10-750 06 55": "For urgent matters, call +46 (0)10-750 06 55",
        "Our office hours are Monday - Friday ​​08.30 - 17.00, with the exception of public holidays": "Our office hours are Monday - Friday ​​08.30 - 17.00, with the exception of public holidays",
        "Your message has been received and we will get back to you shortly": "Your message has been received and we will get back to you shortly",
        "Contact our support": "Contact our support",
        "To newsroom": "To newsroom",
        "Carasent is a software company that develops, sells and delivers e-health services to the Nordic healthcare sector. The business is based on the vision of patient-focused care with minimised administration. The core of the product catalogue is the proprietary web-based medical record system Webdoc": "Carasent is a software company that develops, sells and delivers e-health services to the Nordic healthcare sector. The business is based on the vision of patient-focused care with minimised administration. The core of the product catalogue is the proprietary web-based medical record system Webdoc",
        "About Curoflow Technology AB": "About Curoflow Technology AB",
        "About Carasent Sverige AB": "About Carasent Sverige AB",

        "Curoflow is a platform company providing one of Europe's most comprehensive cloud services for digital patient management, status and history capture and secure patient meetings. Curoflow's mission is to dramatically reduce healthcare queues, simplify the work of healthcare professionals and make tomorrow's digital tools available in a smart way": "Curoflow is a platform company providing one of Europe's most comprehensive cloud services for digital patient management, status and history capture and secure patient meetings. Curoflow's mission is to dramatically reduce healthcare queues, simplify the work of healthcare professionals and make tomorrow's digital tools available in a smart way",
        'For more info, contact': 'For more info, contact',
        'Jag godkänner Curoflows': 'I accept Curoflow’s',
        'Sticka': 'Send',
        "Namn": "Name",
        "010 - 750 06 55": "+46 (0)10 - 750 06 55",
        "I accept Curoflow’s": "I accept Curoflow’s",
        "Curoflow telemedicine platform is a registered medical device under Regulation (EU) 2017/745 of the European Parliament and of the Council on medical devices (the MDR). All data is encrypted, stored and processed in accordance with the Swedish Patient Data Act (Sw. Patientdatalagen) and the EU General Data Protection Regulation (the GDPR)": "Curoflow telemedicine platform is a registered medical device under Regulation (EU) 2017/745 of the European Parliament and of the Council on medical devices (the MDR). All data is encrypted, stored and processed in accordance with the Swedish Patient Data Act (Sw. Patientdatalagen) and the EU General Data Protection Regulation (the GDPR)",
        "Subscribe": "Subscribe",
        "Subscribe to our newsletter": "Subscribe to our newsletter",
        "The latest": "The latest",
        "Your email": "Your email",
        "Telephone": "Telephone",
        "Email": "Email",
        "Ange en giltig e-postadress": "Please enter a valid email address",
        "Stäng": "Close",
        "for more news": "for more news",
        "Here we collect press releases and major news about Curoflow. See also our": "Here we collect press releases and major news about Curoflow. See also our",
        "Tack för att du anmält dig till vårt nyhetsbrev": "Thank you for subscribing to the newsletter",
        "Kontakta support": "Contact support",
        "Karriärsida": "Career",
        "Mån – Fre": "Mon – Fri",
        "Öppettider:": "Opening hours:",
        "Våra ledord": "Our values",
        "E-mail adress": "E-mail address",
        "Vår Lösning": "Our solution",
        "Karriär": "Career",
        "News": "News",
        "Friktionsfria vårdkedjor": "Frictionless care chains",
        "Skapa er egen digitala mottagning med Curoflow. Vi kan digital vård, teknik och datasäkerhet, ni kan och känner er verksamhet och era patienter bäst. Tillsammans möjliggör vi automatiserade processer, effektiva vårdflöden och en patientnära vård.": "Create your own digital practice with Curoflow. We know digital care, technology, and data security. You understand and know your practice and your patients best. Together, we enable automated processes, efficient care flows and a patient-centered care.",
        "Kontakta oss": "Get in touch",
        "Vår lösning är": "Our solution is",
        "Er lösning": "your solution",
        "Genom att skapa flerdimensionella vårdsystem skapar vi unika förutsättningar för just er verksamhet. Berätta för oss vad ni behöver så tar vi fram lösningen. Och just det – det är alltid ert varumärke som syns och finns. Vi stöttar i bakgrunden.": "By creating multidimensional care systems, we enable unique conditions for you and your patients to obtain great results. Tell us what you need, and we’ll present the solution. And right – it’s always your brand that is visible towards the patient. We keep the technology running in the background.",
        "Er digitala mottagning": "Your digital practice",
        "Med stor vikt på enkelhet/användarvänlighet blir tekniken ett hjälpmedel när ni möter patienten. Plattformens lättanvända funktioner och automatiserade processer sätter patienten och vårdgivandet i centrum samtidigt som ni sparar tid.": "With great emphasis on simplicity / user-friendliness, technology becomes an aid when you meet the patient. The platform's easy-to-use functions and automated processes put the patient and care at the center while saving time for healthcare professionals. ",
        "Vision E-hälsa 2025": "Vision E-health 2025",
        "Av läkare och sjuksköterskor är positiva till användande av digital service i välfärden": "Of doctors and nurses are positive to the use of digital services in welfare",
        "Av alla invånare är positiva till vård, konsultation och behandling på distans med stöd av digitala lösningar": "Are positive to receiving care, consultation, and treatment with the support of digital solutions",
        "Upplever det som positivt att kommuner och regioner kommunicerar och erbjuder service digitalt": "Experiences it as positive that municipalities, government, and regions communicate and offer services digitally",
        "Med patienten i fokus": "Patient centered care",
        "Vi hjälper er att framtidssäkra er verksamhet och öka patientnöjdheten. Digitala verktyg som ett komplement till en väl fungerande verksamhet.": "We’ll help you secure the long-term future and success of your practice while increasing patient satisfaction. Using digital tools as a complement to a well-functioning practice.",
        "av": "of",
        "Patienter skulle välja digitala hjälpmedel för grundläggande vårdtjänster men även för specialistvård": "Patients would choose digital tools for basic care services, but also for specialist care",
        "Patienter säger att de gärna byter till en vårdgivare som erbjuder digital service framför en som inte gör det": "Patients say they would be happy to switch to a healthcare provider that offers digital service over one that does not",
        "Patienter säger att en positiv digital upplevelse stärker deras fortroende för vårdgivaren": "Patients say that a positive digital experience strengthens their trust in the caregiver",
        "För att vårdgivare ska klara av att effektivisera och möta det ökade behovet av säker digitalisering i vården, ser jag Curoflow som en självklar del av lösningen.": "In order for caregivers to streamline and meet the increased need for secure digitalization in healthcare, I see Curoflow as an obvious part of the solution.",
        "- Sören Olofsson, Tidigare regeringens särskilde utredare inom hälso- och sjukvård och regiondirektör SLL, samt Region Skåne": "– Sören Olofsson, Former Swedish Government's Special Healthcare Investigator and Regional Director in Stockholm, and Skåne",
        "Källa": "Source",
        "Upptäck Lösningar": "Discover our solutions",
        "Digitala kliniken": "Digital clinic",
        "Curoflow IVF": "Curoflow IVF",
        "Intelligent remittering": "Intelligent referring",
        "Er digitala mottagning. Chatt, video, anamnesupptagning och mycket mer.": "Your digital practice. Chat, video, automated anamnesis and much more.",
        "Digitalt vårdflöde särskilt utvecklat för en kvalitativ IVF-process.": "Digital care flow specially developed for a qualitative IVF process.",
        "Upp till 99% godkända remisser. Revolutionerande lösning för en smart och smidig remitteringskedja.": "Up to 99% approved referrals and 300 searchable diagnoses. Revolutionary solution for smart and secure referring of patients.",
        "Läs mer": "Read more",
        "Om Oss": "About us",
        "En i grunden ideell tjänst skapad av vårdpersonal och tekniska utvecklare, det var så vår resa började. Idag är vår mission att kraftigt reducera vårdköer, förenkla vårdpersonalens arbete och tillgängliggöra morgondagens digitala verktyg på ett klokt sätt.": "A from the beginning non-profit service created by healthcare personnel and technical developers, that's how our journey began. Today, our mission is to greatly reduce care queues, simplify the work of healthcare staff and offer tomorrow's digital tools in a sensible manner.",
        "För partners": "For partners",
        "Vårdgivare behöver snabbt anpassa sig för att möta det ökade behovet av effektivisering och digitalisering utan att kompromissa på säkerhet och kvalitet. Därför skapade vi Curoflow – den intelligenta och multidimensionella vårdplattformen för modern vård. Vill du vara med och möjliggöra framtidens vård?": "Caregivers need to adapt quickly to meet the growing need for efficiency and digitalization without compromising on safety and quality. That's why we created Curoflow - the intelligent and multidimensional care platform for modern care. Do you want to support the future of healthcare?",
        "Kontakta oss så berättar vi mer!": "Get in touch and we’ll tell you more!",
        "Kontakt": "Contact",
        "Vill du veta mer om hur vi tillsammans möjliggör digitalt effektiva lösningar för er verksamhet och era patienter? Ta kontakt med oss idag så berättar vi mer.": "Do you want to know more about how we enable digitally efficient solutions for your business and your patients? Contact us today and let us tell you more.",
        "E-mail": "Email",
        "Meddelande": "Message",
        "Genom att skicka in din E-mailadress godkänner du Curoflows": "By submitting your E-mail address, you agree to Curoflow's",
        "integritetspolicy": "privacy policy",
        "Skicka": "Send",
        "Du kan aven kontakta oss via telefon:": "You can also contact us by phone:",
        "Navigera": "Navigate",
        "Hem": "Home",
        "Vår lösning": "Our solution",
        "Om oss": "About us",
        "För partners": "For partners",
        "Information": "Information",
        "Integritetspolicy": "Privacy policy",
        "Cookies": "Cookies",
        "Språk": "Language",
        "Nyhetsbrev": "Newsletter",
        "Håll dig uppdaterad på nyheter och pressutskick:": "Stay up to date on the latest news and press:",
        "Välkommen till ert nya jag": "Welcome to the new you",
        "Att göra er verksamhet digital eller digifysisk behöver inte vara svårt, tidskrävande eller dyrt. Vi kom från den fysiska vården, vi blev digitala och vi har nu utvecklat möjligheten för fler att ta klivet. Vi hjälper er hela vägen, från start till för alltid.": "Making your practice digital or digiphysical doesn’t have to be difficult, time consuming or expensive. We came from the traditional healthcare, we became digital, and we have now created the opportunity for all of healthcare to make that transition easy, secure and cost-effective. We’ll help you all the way, from now to forever.",
        "Kontakta oss för en introduktion": "Get in touch for an introduction",
        "Varför välja Curoflow?": "Why to choose Curoflow?",
        "Vi kan": "We know",
        "Funktioner": "Made for",
        "för vården": "Healthcare",
        "Lätt att": "Easy to get",
        "komma igång": "   started ",
        "Vi lagrar och hanterar alla personuppgifter och all persondata i Sverige och EU. Till skillnad från majoriteten av leverantörer som är beroende av tredjepartslösningar utanför EU/EES.": "We store and handle all personal data and information in Sweden and the EU. Unlike the majority of providers who depend on third-party solutions outside the EU / EEA.",
        "Hur väl uppfyller ni lagen och hur måna är ni om era patienters uppgifter?": "How well do you comply with the law and how careful are you about your patient’s information?",
        "Curoflow erbjuder möjlighet till en mängd individuella anpassningar. Allt för att passa er verksamhet och de flöden ni behöver. Tonvikt har lagts på enkelhet och automatisering för att förenkla för både er och era patienter.": "Curoflow enables a vast number of individual adaptations. Everything is created to suit your practice and the care flows you need. Emphasis has been placed on simplicity and automation to ease the care-process for both you and your patients.",
        "Vi vill att digitalisering ska vara enkelt och utan barriärer. Vi hjälper er att komma igång dagen efter, eller med mer djupgående utbildningar där vi ser till att alla känner sig trygga i funktion och design.": "We want digitization to be simple and without barriers. We’ll help you get started the next day, or with more in-depth training where we make sure that everyone feels safe in functionality and design.",
        "Vi hjälper er att komma igång": "We’ll help you get started",
        "Vill ni veta varför Curoflow troligen är den säkraste vårdplattformen ur ett GDPR-perspektiv? Undrar ni över hur Curoflow ser ut och hur det hade fungerat i er verksamhet? Är ni redo att byta till en leverantör som kommer från vården och förstår era behov? Oavsett vad finns vi här för att svara på era frågor.": "Are you eager to know why Curoflow probably is the safest healthcare platform from a GDPR perspective? Are you wondering what Curoflow looks like and how it would fit your workflows? Are you ready to switch to a provider who comes from the healthcare sector and understands your needs? No matter what, we’re here to answer your questions.",
        "Välj ditt behov": "Choose what you need",
        "Curoflow plattform": "Curoflow platform",
        "En komplett lösning för alla verksamheter, oavsett storlek": "A complete solution for all practices, regardless of size",
        "Patientchatt": "Patient chat",
        "Chatt för kollegor": "Chat for colleagues",
        "Automatisk status - och anamnesupptagning": "Automated anamnesis summarization",
        "Intelligent formulärverktyg": "Intelligent form creating tool",
        "Schema och kalender": "Schedule and calendar",
        "Drop-in": "Drop-in",
        "Tidbok": "Appointment booking",
        "Kort och swishbetalning": "Card and swish payment",
        "Digital kvittogenerering": "Digital receipt generation",
        "Anpassa utseende och design": "Customize look and design (white label)",
        "Frasbibliotek": "Sentence phrase library",
        "Överför patientärenden": "Transfer patient cases between caregivers",
        "Medicinska formulär med summering": "Medical forms with summery function",
        "Hantering av patient och användarkonton": "Management of patient and user accounts",
        "Integration mot journalsystem": "Integration with EMR/EHR",
        "Och mer...": "And more...",
        "Curoflow Video": "Curoflow video",
        "GDPR-säker videochatt som tillval till plattformen": "GDPR-secure video as a possible add-on",
        "Lagring av persondata i Sverige": "Storage of personal data in Sweden",
        "Krypterad informationsöverföring": "Encrypted transfer of information",
        "Välj mellan 2, 5 eller 10 + deltagare per samtal": "Choose between 2, 5 or 10 + participants per videocall",
        "Bjud in externa parter som ex tolk, anhörig, kollega, ombud": "Invite external participants such as an interpreter, relative or colleague",
        "Håll interna videomöten inom verksamheten": "Have internal video meetings within your practice",
        "BankID-autentisering i samband med inloggning för alla externa deltagare": "E-ID authentication in connection with login for all external participants",
        "Passar även andra verksamheter med höga krav på säkerhet": "Also suitable for other businesses with high demands on security",
        "Kan användas för att säkerställa identitet på samtliga deltagare": "Can be used to ensure the identity of all participant",
        "Curoflow IDP": "Curoflow IDP",
        "Ert namn och varumärke på": "Your name and brand visible on:",
        "BankID": "E-ID",
        "SITHS-Inlogg": "SITHS",
        "SMS och mejlutskick": "SMS and mail notifications",
        "Swish och kortbetalning": "Swish and cardpayment",
        "Curoflow IVF": "Curoflow IVF",
        "Unikt digitalt flöde särskilt framtagen för IVF-processen": "Unique digital flow specially developed for the IVF process",
        "Särskilt framtagna påminnelsefunktioner": "Specially designed reminder functions",
        "Automatiska kallelser till provtagning, besök, m.m.": "Automatic reminders for tests, doctors’ visits, etc.",
        "Digital dagboksfunktion för patient": "Digital diary function for patient",
        "Digital läkemedelsbok med påminnelsefunktion": "Digital prescription calendar with reminder function",
        "Integrerat mot journalsystem": "Possible to integrate with EHR/EMR",
        "Chatt, video, med mera": "Chat, video, and much more… ",
        "Curoflow intelligent remittering": "Curoflow Intelligent referring",
        "Världens första intelligenta remitteringsverktyg": "The world’s first intelligent referring tool",
        "300 diagnoser tillgängliga att söka bland": "300 diagnoses available to search among",
        "Automatiserade beslutsstöd med medicinska riktlinjer": "Automated decision support with medical guidelines",
        "Autogenererade frågor och svar": "Autogenerated questions and answers",
        "Autogenererad remiss": "Auto-generated referrals",
        "Förslag på vårdgivare att remittera till": "Suggestions for caregivers to refer to",
        "99% godkända remisser": "99% approved referrals",
        "Ökad medicinsk säkerhet": "Increased medical safety",
        "Plattform för patientkommunikation": "Patient communication platform",
        "Curoflows plattform för patientkommunikation är dynamisk och skalbar. Den fungerar lika bra om du driver en mottagning med två anställda eller beslutar om hälso- och sjukvård i en hel region. Möjligheten till att digitalisera och effektivera ska inte styras av varken tekniska förkunskaper eller storlek på organisationen. Vi tillgodoser varje kunds specifika behov och tar tillsammans fram lösningar för just er verksamhet.": "Curoflow's patient communication platform is dynamic and scalable. It works equally well if you run a clinic with two employees or manage health care in an entire region. The ability to digitize and streamline care flows shouldn’t be governed by either technical knowledge or size of the organization. We aim to meet each customer's specific needs and work together to provide solutions for your particular practice.",
        "Curoflow video är en säker videokommunikationstjänst som säkerställer att ni efterlever GDPR i er verksamhet. All data lagras, krypteras och hanteras i Sverige vilket gör det möjligt för exempelvis vårdpersonal, jurister eller andra yrkesverksamma med höga krav på säkerhet att genomföra trygga videomöten. Deltagare som ansluter till ett videomöte behöver säkerställa sin identitet genom autentisering med Bank-ID eller SITHS-kort. Deltagare kan ansluta till mötet från surfplatta, dator eller smartphone.": "Curoflow video is a secure video communication service that ensures GDPR-compliance in your business/practice. All data is stored, encrypted and handled in Sweden, which makes it possible for, for example, healthcare professionals, lawyers or other professionals with high demands on security to conduct secure video meetings. Prior to joining a video conference participants need to verify their identity by using E-ID. Participants can connect to meetings from a tablet, computer or smartphone.",
        "Kommer Hösten 2021": "Launch autumn 2021",
        "Med Curoflow-IVF digitaliserar vi en mer eller mindre helt analog process. Inga fler oändliga fax, telefonsamtal, missade tester, information eller möten. Vi hjälper er att kvalitetssäkra, automatisera och spara tid.  Involvera era patienter på ett uppskattat sätt och öka faktorn för lyckade graviditeter. Genom att skapa ett unikt digitalt flöde där patienten alltid vet vad hen ska göra och när hen ska göra det kommer Curoflow IVF vara lösningen er verksamhet och era patienter har väntat på.": "With Curoflow-IVF, we digitize a more or less completely analog process. No more endless faxes, phone calls, missed tests, information or appointments. We help you ensure quality, automate and save time. Involve your patients in an appreciated way and increase the factor for successful pregnancies. By creating a unique digital flow where the patient always knows what to do and when to do it, Curoflow IVF will be the solution your business and your patients have been waiting for.",
        "kommer 2022": "Launch 2022",
        "Remittera patienter med upp till 99% godkännandegrad. Sök bland flera hundra diagnoser och få automatiserade frågor för att fastställa vårdnivå. Om patienten uppfyller fastställda remisskriterier så genererar systemet en remiss per automatik. Möjlighet att välja vårdgivare med rätt kompetens och kortast väntetid presenteras. Uppfylls inte remisskriterierna förser systemet dig med information om lämpliga åtgärder på primärvårdsnivå. Maskininlärning tillämpas vilket gör att eventuella felaktigheter snabbt kan korrigeras.": "Refer patients with up to 99% approval rate. Search among several hundred diagnoses and get automated questions to determine needed level of care. If the patient meets established referral criteria’s, the system automatically generates a referral. If needed criteria’s are not met, the system will provide you with information on appropriate measures to act on at primary care level. Machine learning is applied, which means that inadequate referrals will be auto corrected. ",
        "Vill du veta mer eller få en gratis demo?": "Would you like to know more or get a free demo?",
        "Ta kontakt idag.": "Contact us today.",
        "Namn": "Name",

        "Titel": "Title",
        "Organisation": "Organization",
        "E-mail": "Email",
        "Telefonnummer": "Phone number",
        "Vård. Människor. Empati. Kvalitet. Digitalisering.": "Care. People. Empathy. Quality. Digitization.",
        "En i grunden ideell tjänst skapad av vårdpersonal och tekniska utvecklare, det var så vår resa började. Idag är vår mission att kraftigt reducera vårdköer, förenkla vårdpersonalens arbete och tillgängliggöra morgondagens digitala verktyg på ett klokt sätt. Framförallt har vi två fokus: Patient och vårdutövare.": "A from the beginning non-profit service created by healthcare personnel and technical developers, that's how our journey began. Today, our mission is to greatly reduce care queues, simplify the work of healthcare staff and offer tomorrow's digital tools in a sensible manner. Above all, we have two focuses: Patient and caregiver.",
        "Hur kanaliserar vi bäst vår frustration och vilja att förbättra vården? Ja, ungefär så gick tankarna över några koppar kaffe 2017. Det blev startskottet på RemissHjälpen och sedermera Curoflow. Med viljan att kombinera vård, omsorg och teknik började arbetet mot en eftersatt del av vården – remissflödet. Parallellt har tekniken möjliggjort fler lösningar och vi arbetar nu flerdimensionellt för att lösa såväl digital patientkommunikation, automatisering och vårdflöden.": "How do we best channel our frustration and desire to improve healthcare? Those were our combined thoughts over a few cups of coffee back in 2017. It was the starting point of RemissHjälpen and later Curoflow. With the desire to combine healthcare and technology, work began on a neglected part of the healthcare system - the referral flow. In parallel, our technology and experienced team has enabled more solutions and we are now working multidimensional to solve digital patient communication, automation and multiple care flows.",
        "Vi drivs av det som är viktigt för vården och av det som är viktigt för patienten. Ett friktionsfritt vårdflöde skapas först när värdet blir lika stort för båda delar vårdkedja . Vi strävar efter att hjälpa er, hjälpa andra.": "We are driven by what is important for the healthcare sector and by what is important for the patient. A frictionless care chain is only created when the value becomes equal for both parts of the chain. We strive to help you, help others.",
        "Hos oss arbetar människor som brinner för att förbättra. Vi drivs av möjligheten att bidra till samhället och lägger stor tonvikt på den mänskliga aspekten. Trots vår relativt korta historia har vi väl utformade ESG-kriterier där vi ständigt strävar efter och mäter hur vi bidrar till en bättre miljö och verkar för alla människors lika värde oavsett ursprung, kön, läggning eller religion.": "We work with people who are passionate about improving. We are driven by the opportunity to contribute to society and place great emphasis on the human aspect. Despite our relatively short history, we have well-defined ESG-criteria’s where we constantly strive for and measure how we contribute to a better environment and work for the equal value of all people, regardless of origin, gender, orientation or religion.",
        "Vi vill vara mer än ett vanligt företag. Hos oss skapas mer än teknik. Välkommen till oss så berättar vi mer om hur vi kan hjälpa er verksamhet redan idag!": "We want to be more than a regular company. At our workplace, more than technology is created. Feel welcome to get in touch and we’ll tell you more about how we can help your business or practice today!",
        "Människan i fokus": "A human focus",
        "Med utgångspunkt i patientens behov utvecklar vi våra lösningar till att vara ett värdefullt verktyg för vårdgivare och patienter.": "Based on the patient's needs, we develop our solutions to be a valuable tool for caregivers and patients.",
        "Läs vårt produktblad": "Read our product sheet",
        "Kvalitet": "Quality",
        "För en högkvalitativ vård krävs det säkra och pålitliga lösningar – på riktigt!": "High-quality care requires safe and reliable solutions - for real!",
        "Läs vår kvalitetspolicy": "Read our quality policy",
        "Ansvarsfullt företagande": "Dependable business management",
        "Även om vi arbetar med datorn som arbetsredskap så brinner vi för människor. Läs mer om våra ESG-kriterier.": "Even though we work with the computer as our main working tool, we are passionate about people. Read more about our ESG-criteria’s.",
        "Läs om ESG": "Read about ESG",
        "Vår ledningsgrupp": "Our management team",
        "Kontakta mig": "Contact me",
        "Se fler av våra kollegor": "Meet our other colleagues",
        "Är du nyfiken på hur vi kan hjälpa er verksamhet att komma igång med digitala vårdmöten och verktyg? Hör gärna av dig så berättar vi mer.": "Are you curious about how we can help your practice get started with using digital tools and handling care meetings online? Feel free to contact us and we’ll tell you more.",
        "Vill du alliera dig med aktieägare, företagsledning och medarbetare som brinner för att varje dag tillföra värde och accelerera tillväxt? Då har du hittat rätt!": "Do you want to ally with shareholders, company management and employees who are passionate about creating value and accelerating growth? You’ve found the right team.",
        "Vill du vakna varje morgon och känna att du gör skillnad? Skicka in en spontanansökan eller håll utkik efter lediga jobb. Vi ser fram emot att höra från dig!": "Do you want to wake up every morning and feel that you are making a difference? Submit a spontaneous application or look for vacancies. We look forward to hearing from you!",
        "Följ oss på sociala medier": "Follow us on social media",
        "Är du nyfiken på hur vi kan hjälpa er verksamhet att komma igång med digitala vårdmöten och verktyg? Hör gärna av dig så berättar vi mer. Du når oss per telefon eller mail.": "Are you curious about how we can help your practice or business get started with using digital tools and handling care meetings online? Feel free to contact us and we’ll tell you more. You can reach us by phone or e-mail.",
        "Är du redan kund och behöver support kan du nå oss per telefon helgfri måndag-fredag 08.30-17.00. Vi finns även tillgängliga per mail under kvällar och helger.": "If you’re already a customer and need support, you can reach us by phone on weekdays Monday-Friday 08.30-17.00. We are also available by email during evenings and weekends.",
        "Sälj & Support": "Sales & Support",
        "*Glöm inte att du lättast skapar ett supportärende direkt i plattformen": "* Don’t forget that the easiest way to create a support case is when you are logged into the platform",
        "Jobba med oss": "Work with us",
        "Vill du vara med oss i arbetet att förbättra vården? Besök vår karriärsida och skicka in en intresseanmälan eller sök ditt drömjobb idag!": "Do you want to join our mission to greatly improve healthcare? Visit our career page and send in an expression of interest or apply for your dream job today!",
        "Ansök": "Apply",
        "Därför behövs en hel sida om GDPR": "Why we need a whole page about GDPR",
        "Att leva upp till GDPR är inte ett val man står inför – det är ett måste. Vi vet hur svårt det kan vara och har därför från start lagt fokus på att utveckla Curoflow i enlighet med GDPR och utan tredjepartsleverantörer utanför EU. Låt oss berätta vad som är viktigt och hur Curoflow troligen är det enda enkla, lagliga alternativet i hantering av persondata och personuppgifter.": "Following the rules and being GDPR-compliant isn’t a choice you face – it’s a must. We know how difficult it can be and have placed emphasis on developing Curoflow in accordance with the GDPR and without third-party suppliers outside of EU. Let us tell you what’s important and how Curoflow probably is one of the only easy, legal, option in handling and managing personal data.",
        "Vi tar den korta versionen...": "The short story…",
        "Den 16 juli 2020 meddelade EU-domstolen Schrems II-domen med betydande konsekvenser för användningen av amerikanska och utomeuropeiska molntjänster. Kunder till utomeuropeiska molntjänstleverantörer måste nu själva verifiera dataskyddslagarna i mottagarlandet, dokumentera sin riskbedömning och kommunicera med sina kunder.": "On 16 July 2020, the European Court of Justice announced the Schrems II ruling with significant consequences for the use of US and non-EU/EEA cloud services. Customers of US cloud service providers must now themselves verify the data protection laws of the recipient country, document its risk assessment and confer with its customers.",
        "Den 16 juli 2020 ogiltigförklarade Europeiska unionens domstol (EG-domstolen) i sitt ärende C-311/18 dataskyddskommissionär mot Facebook Ireland och Maximillian Schrems (kallat “Schrems II-målet”) EU – USA Privacy Shield. Domstolen ifrågasatte i vilken utsträckning överföringar kan rättfärdigas genom Europeiska Kommissionens standardavtalsklausuler (SCC) för överföringar av persondata till USA och globalt. SCC: s var fortfarande giltiga som en överföringsmekanism i princip men skulle kräva ytterligare mycket arbete.": "The Court of Justice of the European Union (ECJ) in its Case C-311/18 Data Protection Commissioner v Facebook Ireland and Maximillian Schrems (called “Schrems II case”) invalidated the EU-US Privacy Shield. The Court cast doubt over the extent transfers can be legitimized by the European Commission’s Standard Contractual Clauses (SCC) for personal data transfers to the US and globally. The SCCs were still valid as a transfer mechanism in principle but would require much additional work.",
        "Vad behöver ni tänka på för era patienters skull?": "What you need to bear in mind for your patients sake?",
        "Om ni väljer en leverantör som använder tredjepartslösningar utanför EU/EES måste de och framförallt ni som personuppgiftsansvariga tillse att GDPR efterlevs. Sedan sommaren 2020 är EU-US Privacy Shield inte längre tillämplig vilket innebär att EU:s standardklausuler måste tillämpas vid överföring eller risk för överföring till land utanför EU/EES. För att på ett korrekt sätt efterleva klausulerna krävs enskilt och uttryckligt samtycke från varje användare/patient som använder den tjänst ni har köpt in. Det räcker alltså inte med godkännande av en integritetspolicy.": "If you choose a software that uses third-party cloud services outside the EU / EEA, they and, above all, you as responsible for your customers personal data must ensure that the GDPR is complied with. Since the summer of 2020, the EU-US Privacy Shield is no longer applicable, which means that the EU's standard clauses must be applied in the event of a transfer or risk of transfer to a country outside the EU / EEA. In order to correctly comply with the clauses, the individual and explicit consent of each user / patient who uses the service you are using is required. Thus, approving a privacy policy is not enough.",
        "Utöver det krävs en omfattande utredning om det land där persondata kan komma att hanteras (ex USA). En sådan utredning är så när som på omöjlig att utföra utan en enorm tidsinsats, i både kronor och tid räknat.": "In addition, a comprehensive investigation is required about the country where personal data may be handled (eg the USA). Such an investigation is almost impossible to carry out without an enormous effort, in terms of both money and time.",
        "Ni, ihop med er leverantör behöver tillse att persondata hanteras i enlighet med GDPR. Används tredjepartslösningar utanför EU/EES kan ni så när som på räkna med att nödvändiga åtgärder inte är vidtagna av leverantören och att det ansvaret då faller på er som personuppgiftsansvariga.": "You, together with your software supplier, need to ensure that personal data is handled in accordance with the GDPR. If third-party solutions outside the EU / EEA are part of the platform/cloud service you use, you can more or less expect that necessary measures have not been taken by the supplier and that you and your company are responsible for any illegal handling of personal data in regard to your customers/patients. ",
        "Vad är lösningen och hur gör ni rätt?": "A solution, and how to do it properly?",
        "Det enklaste sättet, oavsett vad man än väljer att göra, är att göra rätt från början. Vi på Curoflow har redan från start utvecklat all teknologi på egen hand. Det innebär att vi till skillnad från de flesta andra alternativ på marknaden kan erbjuda en helt egenutvecklad molntjänst som inte är beroende av tredjepartslösningar utanför EU/EES.": "The easiest way, no matter what you choose to do, is to do right from the start. We at Curoflow have developed all technology on our own, from the beginning. This means that, unlike most other alternatives on the market, we can offer a completely self-developed cloud service that is non-dependent on third-party cloud services outside the EU / EEA.",
        "Exempelvis är vår videochatt egenutvecklad från grunden vilket gör att vi kan lagra och hanteras all persondata i serverhallar i Falkenberg, Sverige. När vi har valt underleverantör för kortbetalning online har vi valt ett svenskt företag och samtidigt valt bort leverantörer som inte kan garantera att persondata hanteras inom EU.": "For example, our video solution is self-developed from the ground up, which means that we can store and manage all personal data at dedicated servers in Falkenberg, Sweden. When we’ve chosen a subcontractor for card payments online, we’ve chosen a Swedish company and at the same time excluded suppliers who cannot guarantee that personal data is handled within the EU.",
        "Genom att välja Curoflows plattform väljer ni inte bara en molntjänst och vårdplattform som kommer att effektivisera och förbättra delar av er verksamhet – ni väljer att ta ansvar för era patienter och deras persondata.": "By choosing Curoflow's platform, you’re not only choosing a cloud service and medical device software that will streamline and improve parts of your practice – you’ll also choose to take responsibility for your patients/customers and their personal data.",
        "Vi förstår att GDPR är krångligt och svårt, men år 2021 har vi inte råd att inte följa lagen och hantera våra patienters persondata på ett korrekt sätt.": "We understand that the GDPR is complicated and difficult, but in 2021 we can’t afford not following the law and to handle our patients' personal data in a correct way.",
        "Kontakta oss för en längre version": "Contact us for a longer version",
        "Vi svarar på era frågor och hjälper er att komma igång": "We’ll answer your questions and help you get started",
        "Ta kontakt med oss så berättar vi mer om GDPR och visar hur Curoflow kan fungera i just er verksamhet.": "Get in touch with us and we’ll tell you more about the GDPR and explain how Curoflow could work in your business/practice.",
        "Ta kontakt": "Get in touch",
        "Satsa på framtidens vård": "Invest in the care of the future",
        "Tror du likt oss att framtiden ligger i kombinationen digital och fysisk vård? Vill du alliera dig med aktieägare, företagsledning och medarbetare som brinner för att varje dag tillföra värde och accelerera tillväxt? Då har du hittat rätt!": "Do you, like us, believe that the future lies in the combination of digital and physical care? Do you want to ally with shareholders, company management and employees who are passionate about adding value every day and accelerating growth? Then you have found the right place!",
        "Några av våra partners": "Some of our partners",
        "Några av våra samarbeten": "Some of our collaborations",


        // Privacy policy
        "Curoflow värdesätter din personliga integritet. I denna integritetspolicy informerar vi dig om hur vi hanterar de personuppgifter vi samlar in eller erhåller via vår webbplats och på andra sätt. Curoflow Technology AB, org. nr. 559123-3654, ('Curoflow') är personuppgiftsansvarig.": "Curoflow values your privacy. In this privacy policy, we inform you about how we handle the personal information we collect or hold via our website and in other ways. Curoflow Technology AB, org. no. 559123-3654, ('Curoflow') is responsible as personal data controller.",
        "Vi behandlar alltid dina personuppgifter i enlighet med gällande dataskyddslagstiftning, inklusive den allmänna dataskyddsförordningen 2016/679 ('GDPR'). För information om vilka cookies vi använder på vår webbplats, se vår": "We always process your personal data in accordance with current data protection legislation, including the general data protection regulation 2016/679 ('GDPR'). For information about which cookies we use on our website, see our",
        "Vems och vilka personuppgifter behandlar vi?": "Whose and what personal data do we process?",
        "Vi behandlar dina personuppgifter i följande fall:": "We process your personal data in the following cases:",
        "När du är kontaktperson hos kund eller annan intressent i samband med att vi utför uppdrag inom vår konsultverksamhet, eller som vi behandlar när uppdraget förbereds, utförs eller administreras. Detta inkluderar även kontaktpersoner hos potentiella kunder, andra rådgivare eller personer som vi önskar att kommunicera med för att informera om våra tjänster.": "When you are the contact person for a customer or other stakeholder in connection with us performing assignments within our business, or which we process when the assignment is prepared, performed or administered. This also includes contact persons at potential customers, other advisors or persons with whom we wish to communicate to inform about our services.",
        "När du är kontaktperson för en till oss potentiell kund eller i annat ärende kontaktar oss via kontaktformulär på vår webbplats, eller direkt till de mejladresser Curoflow har angivet som sina. Detta inkluderar även andra rådgivare eller personer som vi önskar att kommunicera med för att informera om våra tjänster.": "When you are the contact person for a potential customer to us or in another matter, contact us via the contact forms on our website, or directly to the email addresses Curoflow has specified as theirs. This also includes other advisors or people we wish to communicate with to inform about our services.",
        "När du är leverantör, samarbetspartner eller annan part som utför, eller kan komma att utföra, tjänster åt oss.": "When you are a supplier, partner or other party that performs, or may perform, services for us.",
        "När du anmäler dig och/eller deltar i något av våra evenemang, seminarium eller liknande.": "When you register and / or participate in one of our events, seminars or similar.",
        "När du besöker vår webbplats och, i förekommande fall, kontaktar oss via vårt kontaktformulär eller anmäler dig till vårt nyhetsbrev.": "When you visit our website and, where applicable, contact us via our contact forms or sign up for our newsletter.",
        "Personuppgifterna omfattar vanligtvis namn, kontaktuppgifter och titel, men i förekommande fall även bankuppgifter. Vi behandlar dessa personuppgifter för att utföra och administrera kontakten med dig, för att tillvarata dina eller våra intressen, hantera leverantörsförhållanden samt för redovisnings- och faktureringsändamål. Därutöver kan uppgifterna utgöra underlag för intern marknads- och klientanalys och statistik, riskhantering och för metod- och affärsutveckling. Personuppgifterna kan även komma att användas för marknadsföringsåtgärder.": "The personal information usually includes name, contact information and title, and where relevant also bank information. We process this personal data to perform and administer the contact with you, to safeguard your or our interests, to manage supplier relationships and for accounting and invoicing purposes. In addition, the information can form the basis for internal market and client analysis and statistics, risk management and for method and business development. The personal information may also be used for marketing measures.",
        "I samband med evenemang och seminarium kan vi komma att behandla uppgifter om allergier och matpreferenser samt, i vissa fall, bilder och inspelat material på plats. Vi behandlar personuppgifterna för att kunna anordna evenemang och seminarier, besvara din förfrågan om kontakt och/eller för marknadsföringsändamål. På vår webbplats använder vi även cookies för att optimera Webbplatsbesökares upplevelse, se vår": "In connection with events and seminars, we may process information about allergies and food preferences as well as, in some cases, images and recorded material on site. We process personal data in order to be able to organize events and seminars, answer your request for contact and / or for marketing purposes. On our website, we also use cookies to optimize Website visitors' experience, see our",
        "Cookie Policy": "Cookie Policy",
        "för mer information.": "for more information.",
        "Personuppgifterna härrör från dig själv eller din arbetsgivare, medan andra uppgifter kan uppkomma inom ramen för utförande av våra tjänster. I förekommande fall samlar vi in och lagrar personuppgifter från externa källor som offentliga bolags- och adressregister, sökbara och offentliga webbplatser och/eller från kreditupplysningsföretag.": "The personal information originates from yourself or your employer, while other information may arise within the framework of us providing you, our services. Where applicable, we collect and store personal data from external sources such as public records of companies and address registers, searchable and public websites and / or from credit information companies.",
        "Rättslig grund för behandlingen": "Legal basis for processing information",
        "Behandlingen av personuppgifter sker när det är nödvändigt för oss för att vi ska kunna fullgöra vårt avtal med dig eller för att kunna vidta åtgärder innan avtal ingås.": "The processing of personal data takes place when it is necessary for us to be able to fulfill our agreement with you or to be able to take measures before entering into an agreement.",
        "Deltagares och Webbplatsbesökares personuppgifter behandlas för att uppfylla ett berättigat intresse, så som att bjuda in till evenemang eller utskick av nyhetsbrev, där vi bedömt att det berättigade intresset väger tyngre än den registrerades intressen eller grundläggande rättigheter och friheter (intresseavvägning).": "Participants 'and Website Visitors' personal data is processed to fulfill a legitimate interest, such as inviting to events or sending out newsletters, where we have judged that the legitimate interest outweighs the data subject's interests or fundamental rights and freedoms (balancing of interests).",
        "Lagring och utlämning av personuppgifter": "Storage and disclosure of personal data",
        "Vi sparar personuppgifter under den period det är nödvändigt för det aktuella ändamålet för vilket vi behandlar uppgifterna. Om du avanmäler dig för nyhetsbrev kommer uppgifterna, till den del de sparats för sådana ändamål, att raderas. Efter deltagande i evenemang eller seminarium behåller vi de personuppgifter som behandlades för de angivna ändamålen personuppgifter i ett (1) år innan vi raderar dem. Vi vill dock uppmärksamma dig på vid radering av dina personuppgifter för viss behandling kan vi komma att behålla personuppgifterna för andra ändamål, t.ex. om du fortfarande är kund eller leverantör.": "If you unsubscribe from newsletters, the information, to the extent that it has been saved for such purposes, will be deleted. After participating in events or seminars, we retain the personal data that was processed for the stated purposes personal data for one (1) year before we delete them. However, we would like to draw your attention to the fact that when deleting your personal data for certain processing, we may retain the personal data for other purposes, e.g., if you are still a customer or supplier.",
        "Överföring till land utanför EU/EES": "Transfer to countries outside the EU / EEA",
        "Curoflow kan anlita externa samarbetspartners och leverantörer att utföra uppgifter för vår räkning, t ex för att tillhandahålla IT-tjänster, analyser eller statistik. Utförandet av dessa tjänster kan innebära att våra samarbetspartners, både inom och utanför EU/EES, får tillgång till era personuppgifter.": "Curoflow may engage external partners and suppliers to perform tasks on our behalf, for example to provide IT services, analytics or statistics. The performance of these services may mean that our partners, both within and outside the EU / EEA, gain access to your personal data.",
        "Samarbetspartners som hanterar personuppgifter för Curoflow:s räkning undertecknar alltid avtal med oss för att säkerställa en hög skyddsnivå för era personuppgifter. I förhållande till samarbetspartners utanför EU/EES vidtas i nödvändiga fall dessutom särskilda skyddsåtgärder, t.ex. genom att vi ingår avtal som inkluderar standardavtalsklausuler för dataöverföring i enlighet med EU-kommissionens beslut och som finns tillgängliga på EU-kommissionens hemsida.": "Partners who handle personal data on behalf of Curoflow always sign agreements with us to ensure a high level of protection for your personal data. In relation to partners outside the EU / EEA, special protective measures are also taken in necessary cases, e.g., by entering into agreements that include standard agreement clauses for data transmission in accordance with the European Commission's decision and which are available on the European Commission's website.",
        "Denna integritetspolicy avser att beskriva det sätt vi hanterar personuppgifter från våra befintliga eller potentiella kunder. De personuppgifter (från vårdpersonal, administratörer och patienter) som hanteras i Curoflows medicintekniska plattform hanteras inom EU/EES och utan risk för överföring utanför EU/EES.": "This privacy policy is only intended to describe the way we handle personal data from our existing or potential customers. The personal data (from healthcare professionals, administrators and patients) that is handled and managed within Curoflow's medical device software is handled within the EU / EEA and without the risk of transfer outside the EU / EEA.",
        "Dina rättigheter": "Your rights",
        "Nedan följer en lista över de rättigheter du har avseende vår behandling av dina personuppgifter. Rättigheterna är inte absoluta och en begäran om utövande av rättigheterna resulterar därför inte alltid i någon åtgärd.": "Below is a list containing rights you have regarding our processing of your personal data. The rights are not absolute and a request for exercising the rights does therefore not always result in any action.",
        "Dina rättigheter i GDPR inkluderar:": "Your rights in the GDPR include:",
        "Rätt till tillgång – Enligt artikel 15 GDPR har du rätt att få tillgång till dina personuppgifter samt viss information rörande behandlingen av dem. Den informationen framgår av detta dokument.": "Right to access - According to Article 15 GDPR, you have the right to access your personal data and certain information regarding the processing of them. That information is provided in this document.",
        "Rätt till rättelse – Enligt artikel 16 GDPR har du rätt att få felaktiga personuppgifter rättade samt att komplettera ofullständiga personuppgifter.": "Right to rectification - According to Article 16 GDPR, you have the right to have incorrect personal data corrected and to supplement incomplete personal data.",
        "Rätt till radering – Under vissa omständigheter har du enligt artikel 17 GDPR rätt att få personuppgifterna raderade. Detta är den så kallade 'rätten att bli bortglömd'.": "Right to deletion - In certain circumstances, according to Article 17 of the GDPR, you have the right to have your personal data deleted. This is the so-called 'right to be forgotten'.",
        "Rätt till begränsning av behandling – Under vissa omständigheter har du enligt artikel 18 GDPR rätt att begränsa behandlingen som vi vidtar.": "Right to limit processing - Under certain circumstances, you have the right under Article 18 GDPR to limit the processing of personal data we take.",
        "Rätt till dataportabilitet – Enligt artikel 20 GDPR har du rätt att få ut personuppgifterna (eller få dem överförda till en annan personuppgiftsansvarig) i ett strukturerat, allmänt använt och maskinläsbart format.": "Right to data portability - According to Article 20 GDPR, you have the right to obtain the personal data (or have it transferred to another personal data controller) in a structured, commonly used and machine-readable format.",
        "Rätt att göra invändningar – Enligt artikel 21 GDPR har du rätt att invända mot särskilda personuppgiftsbehandlingar.": "Right to object - Under Article 21 of the GDPR, you have the right to object to specific processing of personal data.",
        "Kontaktinformation": "Contact information",
        "Kontakta oss på": "Contact us at ",
        "om du har några frågor angående vår personuppgiftsbehandling eller önskar utöva dina rättigheter enligt ovan. Om du är missnöjd med vår behandling finns möjlighet att framföra klagomål hos Integritetsskyddsmyndigheten via www.imy.se. Du kan även vända dig till tillsynsmyndigheten i det land där du bor eller arbetar.": "se if you have any questions regarding our personal data processing or wish to exercise your rights as above. If you are dissatisfied with our treatment, it is possible to file a complaint with the Privacy Protection Agency in Sweden via www.imy.se. You can also contact the supervisory authority of the country where you live or work.",
        "Förtydligande:": "Clarification:",

        // Cookies
        "Vad är cookies?": "What are cookies?",
        "Curoflow Technology AB, org. nr. 559123-3654, ('Curoflow') använder cookies för att anpassa vår webbplats och optimera din upplevelse. Cookies är små textfiler som lagras på din enhet när du besöker webbplatser.": "Curoflow Technology AB, org. no. 559123-3654, ('Curoflow') uses cookies to personalize our website and optimize your experience. Cookies are small text files that are stored on your device when you visit websites.",
        "Det finns olika typer av cookies. Sessionscookies lagras temporärt tills du har stängt ned din webbläsare medan permanenta cookies sparas under en längre tid. Cookies kan vidare delas upp i förstapartscookies, som vi sätter själva på vår webbplats, och tredjepartscookies, som tillhör en annan domän. På vår webbplats använder vi oss av permanenta första- och tredjepartscookies. Vi behandlar inga personuppgifter i våra cookies.": "There are different types of cookies. Session cookies are stored temporarily until you have closed your browser while permanent cookies are stored for a longer period of time. Cookies can also be divided into first-party cookies, which we place on our website, and third-party cookies, which belong to another domain. On our website, we use permanent first- and third-party cookies. We do not process personal data in our cookies.",
        "Vi har delat upp våra cookies i kategorierna 'nödvändiga cookies' och 'statistikcookies'. Nödvändiga cookies används för att vår webbplats ska fungera på ett säkert sätt. Statistikcookies tillhandahåller analys av data och webbplatstrafik som gör att vi kan anpassa användarupplevelsen på vår webbplats.": "We have divided our cookies into the categories 'necessary cookies' and 'statistics cookies'. Necessary cookies are used for our website to function in a secure manner. Statistics cookies provide analysis of data and website traffic that allows us to customize the user experience on our website.",
        "Vilka cookies använder vi?": "What cookies do we use?",
        "För att vara transparenta har vi sammanfattat information om de cookies vi använder på vår hemsida nedan.": "To be transparent, we have summarized information about the cookies we use on our website below.",
        "Nödvändiga cookies": "Necessary cookies",
        "Namn på cookie: cookielawinfo-checkbox-necessary": "Cookie name: cookielawinfo-checkbox-necessary",
        "Typ av cookie: Permanent förstapartscookie": "Type of cookie: Permanent first-party cookie",
        "Ändamål: Hantering av vår cookiebanner, spara besökares godkännande av nödvändiga cookies": "Purpose: Management of our cookie banner, save visitors' approval of necessary cookies",
        "Lagringstid: 11 månader": "Storage time: 11 months",
        "Datan delas inte": "The data is not shared",
        "Namn på cookie: cookielawinfo-checkbox-non-necessary": "Cookie name: cookielawinfo-checkbox-non-necessary",
        "Typ av cookie: Permanent förstapartscookie": "Type of cookie: Permanent first-party cookie",
        "Ändamål: Hantering av vår cookiebanner, spara besökares godkännande av statistikcookies.": "Purpose: Management of our cookie banner, save visitors' approval of statistics cookies.",
        "Namn på cookie: viewed_cookie_policy": "Cookie name: viewed_cookie_policy",
        "Typ av cookie: Permanent förstapartscookie": "Type of cookie: Permanent first-party cookie",
        "Ändamål: Hantering av vår cookiebanner, spara huruvida besökaren har godkänt användningen av cookies.": "Purpose: Management of our cookie banner, save whether the visitor has approved the use of cookies.",
        "Statistikcookies": "Statistics cookies",
        "Namn på cookie: _ga": "Name of cookie: _ga",
        "Leverantör: Google": "Provider: Google",
        "Typ av cookie: Permanent tredjepartscookie": "Type of cookie: Permanent third-party cookie",
        "Ändamål: Identifiering av unika besökare och beteende på webbplatsen": "Purpose: Identification of unique visitors and behavior on the website",
        "Lagringstid: 2 år": "Storage time: 2 years",
        "Data delas till Google LLC": "Data is shared with Google LLC",
        "Namn på cookie: _gat": "Cookie name: _gat",
        "Ändamål: Implementering av anropsbegränsning vid hög webbplatstrafik": "Purpose: Implementation of call restriction in case of high website traffic ",
        "Lagringstid: 1 minut": "Storage time: 1 minute",
        "Namn på cookie: _gid": "Name of cookie: _gid",
        "Ändamål: Identifiering av unika besökare Lagringstid: 24 timmar": "Purpose: Identification of unique visitors Storage time: 24 hours",
        "Lagringstid: 24 timmar": "Storage time: 24 hours",

        "Personuppgifter": "Personal information",
        "Du har möjlighet att ändra dina webbläsarinställningar så att du uppmärksammas om en cookie skickas till din enhet eller för att ange som huvudregel att cookies blockeras. Du kan också välja att radera alla de cookies som finns lagrade på din dator. Curoflow:s användning av nödvändiga cookies är enligt ovan i vissa fall väsentliga för att tillhandahålla viktiga funktioner på vår webbplats. Om du väljer att blockera våra cookies kan det innebära viss begränsad funktionalitet på webbplatsen. Vad avser de cookies som levereras av Google finns särskilda tillägg att hämta": "You have the option to change your browser settings so that you are notified if a cookie is sent to your device or to specify as a general rule that cookies are blocked. You can also choose to delete all cookies stored on your computer. Curoflow's use of necessary cookies is, as above, in some cases essential to provide important functions on our website. If you choose to block our cookies, this may mean some limited functionality on the website. Regarding the cookies provided by Google, there are special extensions to download",
        "här": "here",
        "Du kan ändra ditt godkännande av statistikcookies i vår cookiebanner. Ändrade cookieinställningar börjar gälla från och med tillfället för ändringen och påverkar inte tidigare insamlad information via cookies.": "You can change your acceptance of statistics cookies in our cookie banner. Changed cookie settings take effect from the time of the change and do not affect previously collected information via cookies.",
        "För mer information om cookies, se": "For more information about cookies, see",
        "För information om hur vi hanterar dina personuppgifter, se vår": "For information on how we handle your personal information, see our",

        /*
        "Välkommen till ert nya jag": "Welcome to your new self",
        "Att göra er verksamhet digital eller digifysisk behöver inte vara svårt, tidskrävande eller dyrt. Vi kom från den fysiska vården, vi blev digitala och vi har nu utvecklat möjligheten för fler att ta klivet. Vi hjälper er hela vägen, från start till för alltid.": "Making your business digital or digi-physical does not have to be difficult, time consuming or expensive. We came from physical care, we went digital and we have now developed the opportunity for more people to take the step. We help you all the way, from start to forever.",
        "Kontakta oss för en introduktion": "Contact us for an introduction",
        "Varför välja Curoflow?": "Why choose Curoflow?",
        "Vi kan": "We know",
        "Funktioner": "Features",
        "för vården": "for healthcare",
        "Lätt att": "Easy to",
        "komma igång": "get started",
        "Vi lagrar och hanterar alla personuppgifter och all persondata i Sverige och EU. Till skillnad från majoriteten av leverantörer som är beroende av tredjepartslösningar utanför EU/EES.": "We store and handle all personal data and all personal data in Sweden and the EU. Unlike the majority of suppliers who depend on third-party solutions outside the EU / EEA.",
        "Hur väl uppfyller ni lagen och hur måna är ni om era patienters uppgifter?": "How well do you comply with the law and how caring are you about your patients' information?",
        "Curoflow erbjuder möjlighet till en mängd individuella anpassningar. Allt för att passa er verksamhet och de flöden ni behöver. Tonvikt har lagts på enkelhet och automatisering för att förenkla för både er och era patienter.": "Curoflow offers the possibility of a number of individual adaptations. Everything to suit your business and the flows you need. Emphasis has been placed on simplicity and automation to simplify for both you and your patients.",
        "Vi vill att digitalisering ska vara enkelt och utan barriärer. Vi hjälper er att komma igång dagen efter, eller med mer djupgående utbildningar där vi ser till att alla känner sig trygga i funktion och design.": "We want digitization to be simple and without barriers. We help you get started the next day, or with more in-depth training where we make sure that everyone feels safe in function and design.",
        "Vi hjälper er att komma igång": "We help you get started",
        "Vill ni veta varför Curoflow troligen är den säkraste vårdplattformen ur ett GDPR-perspektiv? Undrar ni över hur Curoflow ser ut och hur det hade fungerat i er verksamhet? Är ni redo att byta till en leverantör som kommer från vården och förstår era behov? Oavsett vad finns vi här för att svara på era frågor.": "Want to know why Curoflow is probably the safest healthcare platform from a GDPR perspective? Are you wondering what Curoflow looks like and how it would have worked in your business? Are you ready to switch to a provider who comes from healthcare and understands your needs? No matter what, we are here to answer your questions.",
        "Satsa på framtidens vård": "Invest in the future of healthcare",
        "Tror du likt oss att framtiden ligger i kombinationen digital och fysisk vård? Vill du alliera dig med aktieägare, företagsledning och medarbetare som brinner för att varje dag tillföra värde och accelerera tillväxt? Då har du hittat rätt!.": "Do you, like us, believe that the future lies in the combination of digital and physical care? Do you want to ally with shareholders, company management and employees who are passionate about adding value every day and accelerating growth? Then you have found the right place!",
        "Är du nyfiken på hur vi kan hjälpa er verksamhet att komma igång med digitala vårdmöten och verktyg? Hör gärna av dig så berättar vi mer. Du når oss per telefon eller mail.": "Are you curious about how we can help your business get started with digital care meetings and tools? Feel free to contact us and we will tell you more. You can reach us by phone or email.",
        "Är du redan kund och behöver support kan du nå oss per telefon helgfri måndag-fredag 08.30-17.00. Vi finns även tillgängliga per mail under kvällar och helger.": "If you are already a customer and need support, you can reach us by phone on weekends Monday-Friday 08.30-17.00. We are also available by email during evenings and weekends."
        */

        "Vi använder cookies": "We use cookies",
        "Acceptera alla": "Accept all",
        "Acceptera nödvändiga": "Accept the necessary",
        "För att optimera ditt användande av vår webbplats använder Curoflow nödvändiga cookies. Om du samtycker använder vi även statistik- & marknadsföringscookies i syfte att analysera webbplatstrafik och erbjuda mer relevant kommunikation. Datan delas med cookieleverantören och du kan återkalla ditt samtycke när som helst.": "To optimize your use of our website, Curoflow uses necessary cookies. If you agree, we also use statistics & marketing cookies in order to analyze website traffic and offer more relevant communication. The data is shared with the cookie provider and you can revoke your consent at any time."

    }
}
